import { Box, PanelBox } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex: 1;
  margin: 40px 0;

  ${MediaQuery.phone`
    margin: 32px 0;
  `}
`

export const Content = styled(PanelBox)`
  padding: 25px 30px;
  margin-top: 40px;

  max-width: 550px;
  width: 100%;

  ${MediaQuery.tablet`
    max-width: unset;
    padding: 25px 16px;
    margin-top: 32px;
  `};
`

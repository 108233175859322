/* eslint-disable no-debugger */
import { BigNumber } from '@ethersproject/bignumber'
import { useQuery } from '@tanstack/react-query'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import { chainIdToNetworkName } from 'lib/hooks/useCurrencyLogoURIs'
import { useMemo } from 'react'
import { getBigNumberValue } from 'utils/getBignumberValue'
import { BN_1E18, ZERO } from 'utils/isZero'

import { fetcherZapperGet, fetcherZapperPost } from './queryTools'

export interface IToken {
  address: string
  balance: number
  balanceRaw: number
  balanceUSD: number
  canExchange: boolean
  coingeckoId: string
  createdAt: string
  dailyVolume: number
  decimals: number
  externallyVerified: boolean
  hide: false
  holdersEnabled: true
  id: string
  label: string
  marketCap: string
  name: string
  networkId: 1
  price: number
  priceUpdatedAt: number
  status: string
  symbol: string
  totalSupply: string
  updatedAt: string
  verified: boolean
}

export interface IAppToken {
  address: string
  appId: string
  appImage: string
  appName: string
  balanceUSD: number
  key: string
  network: string
}

interface IBalance {
  tokenBalances: IToken[]
  appBalances: IAppToken[]
  value: {
    marketValue: number
  }
  mappedBalances: Record<string, IToken>
}

const FIFTY_SECONDS = 15000
const TEN_SECONDS = 10000

const useInitRequest = (
  account: string,
  chains: string,
  type: string,
  condition: boolean,
  checkJobs: boolean,
  key: string,
) => {
  return useQuery<any, Error, any, [string]>({
    queryKey: [`use${type}_${key}_Init_${account}_${chains}`],
    queryFn: async () => {
      return fetcherZapperPost(Authorization)(
        `https://api.zapper.xyz/v2/balances/${type}?addresses%5B%5D=${account}&${chains}`,
      ).then((res) => {
        return res
      })
    },
    enabled: !!account && checkJobs && condition,
    refetchInterval: FIFTY_SECONDS,
  })
}

const useZappierLoader = (type: string = 'tokens' || 'apps', checkJobs = false, chains = '') => {
  const { account = '' } = useWeb3React()

  const dataLoader = useQuery<any, Error, any, [string]>({
    queryKey: [`use${type}_${account}_${chains}`],
    queryFn: async () => {
      return fetcherZapperGet(Authorization)(
        `https://api.zapper.xyz/v2/balances/${type}?addresses%5B%5D=${account}&${chains}`,
      )
    },
    refetchInterval: FIFTY_SECONDS,
    enabled: !!account,
  })

  const initRequest = useInitRequest(
    account,
    chains,
    type,
    dataLoader?.data && Object.keys(dataLoader?.data).length === 0 && !dataLoader.isLoading,
    checkJobs,
    'Init',
  )

  const jobStatus = useQuery<any, Error, any, [string]>({
    queryKey: [`use${type}JobStatus_${account}_${chains}`],
    queryFn: async () => {
      if (!initRequest.data?.jobId) {
        return null
      }
      return fetcherZapperGet(Authorization)(
        `https://api.zapper.xyz/v2/balances/job-status?jobId=${initRequest.data?.jobId}`,
      )
    },
    refetchInterval: TEN_SECONDS,
    enabled: !!account && !!initRequest.data?.jobId && checkJobs,
  })

  useInitRequest(
    account,
    chains,
    type,
    jobStatus?.data?.status === 'completed' && !jobStatus.isLoading,
    checkJobs,
    'Reinit',
  )

  return {
    dataLoader,
    initRequest,
    jobStatus,
    isLoading: dataLoader.isLoading || initRequest.isLoading || jobStatus.isLoading,
  }
}

const ZAPPER_KEY = 'e165fbb8-b574-47ea-9d5c-db33a88749e2'
const Authorization = `Basic ${Buffer.from(`${ZAPPER_KEY}:`, 'binary').toString('base64')}`

const getTokens = (tokens: any, account: string) => (tokens[account.toLowerCase()] || []).map((item: any) => item.token)

export const useAccountTokens = (
  chainId?: number,
  checkJobs = false,
): {
  isLoading: boolean
  data: IBalance
} => {
  const { account = '' } = useWeb3React()

  const chains = useMemo(() => {
    return chainId
      ? `networks%5B%5D=${chainIdToNetworkName(chainId)}`
      : `networks%5B%5D=${chainIdToNetworkName(SupportedChainId.POLYGON)}&networks%5B%5D=${chainIdToNetworkName(
          SupportedChainId.MAINNET,
        )}`
  }, [chainId])

  const { dataLoader: tokensJob, isLoading: isLoadingTokens } = useZappierLoader('tokens', checkJobs, chains)

  const { dataLoader: appsJob, isLoading: isLoadingApps } = useZappierLoader('apps', checkJobs, chains)

  return useMemo(() => {
    const hasDataTokens = !!tokensJob.data

    const hasDataApps = !!appsJob.data && Array.isArray(appsJob.data)

    const tokenBalances = hasDataTokens ? getTokens(tokensJob.data, account) : []

    const balancesMapped = tokenBalances.reduce((acc: any, item: any) => {
      acc[item.address.toLowerCase()] = item
      return acc
    }, {})

    return {
      isLoading: isLoadingTokens || isLoadingApps,
      data: {
        apps: appsJob,
        tokens: tokensJob,
        appBalances: hasDataApps ? appsJob.data : [],
        tokenBalances: hasDataTokens ? getTokens(tokensJob.data, account) : [],
        mappedBalances: balancesMapped,
        value: {
          marketValue:
            (hasDataApps
              ? appsJob?.data?.reduce((acc: number, item: IAppToken) => {
                  return item.balanceUSD ? acc + +item.balanceUSD : acc
                }, 0) || 0
              : 0) +
            (hasDataTokens
              ? getTokens(tokensJob.data, account)?.reduce((acc: number, item: IAppToken) => {
                  return item.balanceUSD ? acc + +item.balanceUSD : acc
                }, 0) || 0
              : 0),
        },
      },
    }
  }, [appsJob, tokensJob, account, isLoadingApps, isLoadingTokens])
}

export const useTokensUsdValue = (
  tokens: Array<{
    address: string
    amount: number | string
  }>,
) => {
  const { chainId } = useWeb3React()

  const {
    data: { mappedBalances },
    isLoading,
  } = useAccountTokens(chainId)

  return useMemo(() => {
    if (isLoading) {
      return {
        isLoading,
        val: ZERO,
      }
    }

    const totalValues: BigNumber[] = []

    tokens.forEach((token) => {
      const targetToken = mappedBalances[token.address.toLowerCase()]

      if (targetToken && token.amount) {
        const priceAsBigNumber = getBigNumberValue(targetToken.price)
        const valueAsBigNumber = BigNumber.from(token.amount.toString()).mul(10 ** (18 - targetToken.decimals))

        const val = valueAsBigNumber.mul(priceAsBigNumber).div(BN_1E18)

        totalValues.push(val)
      }
    })

    return {
      isLoading: false,
      val: totalValues.reduce((acc, item) => acc.add(item), ZERO),
    }
  }, [isLoading, mappedBalances, tokens])
}

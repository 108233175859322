import { Dialog, TextField } from '@mui/material'
import { Box, Button, PanelBox } from 'components'
import styled from 'styled-components/macro'

export const DialogContainer = styled(Dialog)`
  .MuiPaper-root {
    background: transparent;
    max-width: 600px;
    width: 100%;
    height: 600px;
  }
`

export const DialogContent = styled(PanelBox)`
  padding: 30px;
  height: 100%;
`

export const SearchInput = styled(TextField)`
  margin-top: 25px;
  .MuiOutlinedInput-root {
    border-radius: 10px;

    .MuiInputBase-input {
      padding-top: 12px;
      padding-bottom: 11px;
    }
  }
`

export const TokenItemContainer = styled(Button).attrs({
  variant: 'text',
})<{ $noDivider: boolean }>`
  min-height: 60px;
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}
  justify-content: flex-start;
`

export const TokenList = styled(Box)`
  margin-top: 25px;
  overflow-y: scroll;
`

import { BigNumber } from '@ethersproject/bignumber'
import { SwapInfo } from 'blocks'
import { AmountInputWithMax } from 'blocks/AmountInputRefactored/AmountInput'
import { Box } from 'components'
import Loading from 'components/Loading'
import { CheckLoginBtn } from 'components/Web3Status'
import { ApproveCheckerVault } from 'hooks/useApproveCallback'
import { useTrans } from 'i18n/useTransHook'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useTokenBalance } from 'pages/StakeListPage/StakeList/hooks'
import { IStakeView } from 'pages/StakeListPage/StakePage/StakeView/useStakeView'
import { useMemo, useState } from 'react'
import { useVaultMasterchefV2 } from 'utils/blackbunny'
import { fromWei } from 'utils/fromWei'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import { useParticipateInVault, useWithdrawFromVault } from './hooks'
import { ConfirmButton, Container, Footer } from './styles'

const isV3Version = (version: string) => (version ? version.startsWith('3.') : false)

const DEFAULT: string[] = []

const useExpectedReceive = (vault: string, amount: number, decimals: number) => {
  const contract = useVaultMasterchefV2(vault)
  const result = useSingleCallResult(contract, 'pricePerShare', DEFAULT)

  return {
    loading: !result?.result || result.loading,
    amount: result.result?.[0] ? amount / +fromWei(result.result?.[0], decimals) : 0,
  }
}

const useExpectedWithdraw = (vault: string, amount: number, decimals: number) => {
  const contract = useVaultMasterchefV2(vault)
  const result = useSingleCallResult(contract, 'pricePerShare', DEFAULT)

  return useMemo(() => {
    return {
      loading: !result?.result || result.loading,
      amount: result.result?.[0] ? +fromWei(result.result?.[0], decimals) / amount : 0,
    }
  }, [result, amount, decimals])
}

const useFormProps = () => {
  const [firstInput, changeFirstInput] = useState<BigNumber | undefined>(ZERO)

  return {
    firstInput,
    changeFirstInput,
  }
}

const getMultiplied = (firstInput: BigNumber | undefined, receivePerOne: number) => {
  if (!firstInput) return ZERO

  const rounder = 10000

  const target = Math.round(receivePerOne * rounder)

  return firstInput.mul(target).div(rounder)
}

/**
 * Stake View
 * @constructor
 */
export const StakeView = (props: IStakeView) => {
  const {
    vault: {
      token: { decimals, address: targetTokenAddress, symbol: stakingSymbol, name: stakingName },
      symbol,
      name,
      address: vaultAddress,
      version,
    },
  } = props
  const { t } = useTrans('StakePage')

  const { balance } = useTokenBalance(targetTokenAddress)
  const { balance: vaultBalance } = useTokenBalance(vaultAddress)

  const { firstInput, changeFirstInput } = useFormProps()

  const isV3 = isV3Version(version)

  const { action, pending } = useParticipateInVault(isV3, vaultAddress, firstInput)
  const { amount: receivePerOne } = useExpectedReceive(vaultAddress, 1, decimals)

  const rightTokenInfo = useMemo(() => {
    return {
      symbol: stakingSymbol,
      name: stakingName,
    }
  }, [stakingSymbol, stakingName])

  return (
    <Container>
      <AmountInputWithMax
        label={t('From wallet')}
        showBalanceRow={true}
        inputValue={firstInput}
        rightToken={rightTokenInfo}
        decimals={decimals}
        setInputValue={(v) => v && changeFirstInput(v)}
        max={balance}
      />

      <Box height={30} />

      <AmountInputWithMax
        label={t('You will receive')}
        showBalanceRow={false}
        inputValue={getMultiplied(firstInput, receivePerOne)}
        disabled
        rightToken={{
          symbol,
          name,
        }}
        decimals={decimals}
      />

      <SwapInfo title={`${formatDecimal(vaultBalance, 2, decimals)} ${symbol}`} desc={t('Deposited')} mt={20} />
      <SwapInfo title={`1 ${symbol} = ${(1 / receivePerOne).toFixed(4)} ${stakingSymbol}`} desc={t('Rate')} mt={5} />

      <Footer>
        <CheckLoginBtn>
          <ApproveCheckerVault isV3={isV3} spendingToken={targetTokenAddress} amount={firstInput || ZERO}>
            <ConfirmButton onClick={action} disabled={pending || !firstInput || firstInput?.isZero()}>
              <Loading loading={pending}> {t('Confirm')}</Loading>
            </ConfirmButton>
          </ApproveCheckerVault>
        </CheckLoginBtn>
      </Footer>
    </Container>
  )
}

export const UnStakeView = (props: IStakeView) => {
  const {
    vault: {
      token: { decimals, name: tokenName, symbol: stakingSymbol },
      symbol,
      address: vaultAddress,
      version,
    },
  } = props
  const { t } = useTrans('StakePage')

  const { balance: vaultBalance } = useTokenBalance(vaultAddress)

  const { firstInput, changeFirstInput } = useFormProps()

  const isV3 = isV3Version(version)

  const { action, pending } = useWithdrawFromVault(isV3, vaultAddress, firstInput)
  const { amount: receivePerOne } = useExpectedWithdraw(vaultAddress, 1, decimals)

  return (
    <Container>
      <AmountInputWithMax
        label={t('From vault')}
        inputValue={firstInput}
        rightToken={{
          symbol,
          name: tokenName,
        }}
        decimals={decimals}
        balance={vaultBalance}
        setInputValue={(v) => v && changeFirstInput(v)}
      />

      <Box height={30} />

      <AmountInputWithMax
        label={t('You will receive')}
        showBalanceRow={false}
        inputValue={getMultiplied(firstInput, receivePerOne)}
        disabled
        rightToken={{
          symbol: stakingSymbol,
          name: tokenName,
        }}
        decimals={decimals}
      />

      <SwapInfo title={`1 ${stakingSymbol} = ${(1 / receivePerOne).toFixed(4)} ${symbol}`} desc={t('Rate')} mt={20} />

      <Footer>
        <CheckLoginBtn>
          <ConfirmButton onClick={action} disabled={pending || !firstInput || firstInput?.isZero()}>
            <Loading loading={pending}> {t('Confirm')}</Loading>
          </ConfirmButton>
        </CheckLoginBtn>
      </Footer>
    </Container>
  )
}

import { Typography } from '@mui/material'
import { Box } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  margin: 40px 0;

  ${MediaQuery.tablet`
    margin: 20px 0;
  `}
`

export const Title = styled(Typography)`
  font-size: 33px;

  ${MediaQuery.tablet`
    font-size: 30px;
  `}
`

export const Content = styled(Box)`
  flex-direction: row;

  align-items: flex-start;
  margin-top: 15px;
  padding-bottom: 40px;

  ${MediaQuery.extraLarge`
    flex-direction: column;    
  `}

  ${MediaQuery.phone`
    padding-bottom: 20px;
  `}
`

export const ButtonWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
`

export const RightPanel = styled(Box)`
  margin-left: 17px;
  flex: 1;

  ${MediaQuery.extraLarge`
    flex: none;
    align-self: stretch;
    margin: 20px 0 0;    
  `}
`

import gql from 'graphql-tag'
import { useMemo } from 'react'

import { useApiBlockNumber, useApiQuery, useSWR } from './graphql'

const query = gql`
  query tokens($address: String) {
    tokens(where: { referralRewards_some: { referrer: { address_eq: $address } } }) {
      address
    }
  }
`

export interface IToken {
  address: string
}

export function useTokensByAddress({ address }: { address?: string }): {
  list: IToken[]
  loading: boolean
  error: any
} {
  const block = useApiBlockNumber()

  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (address) {
      vars.address = address.toLowerCase()
    }

    return vars
  }, [address, block])

  const fetchApi = useApiQuery(query, vars)
  const { data, error } = useSWR(`tokens${JSON.stringify(vars)}`, fetchApi)

  return {
    list: data ? data.tokens : [],
    loading: !data,
    error,
  }
}

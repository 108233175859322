import { Slider } from '@mui/material'
import { Box, GradientButton, PanelBox } from 'components'
import styled from 'styled-components/macro'
import { GradientColors } from 'utils/colors'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex: 1;
`

export const PanelLayout = styled(Box)`
  flex-direction: row;

  ${MediaQuery.tablet`
    flex-direction: column;
  `}
`

export const LeftLayout = styled(Box)`
  max-width: 600px;
  width: 100%;

  ${MediaQuery.tablet`
    max-width: unset;
  `}
`

export const RightLayout = styled(Box)`
  max-width: 550px;
  width: 100%;
  margin-left: 15px;

  ${MediaQuery.tablet`
    max-width: unset;
    margin: 24px 0 0;
  `}
`

export const TransferPanel = styled(PanelBox)`
  padding: 32px;

  ${MediaQuery.screen`
    padding: 20px 10px;
  `};
  ${MediaQuery.tablet`
    padding: 25px 16px;
  `};
`

export const InfoPanel = styled(PanelBox)`
  padding: 24px 32px;

  ${MediaQuery.tablet`
    display: none;
  `}
`

export const ConfirmButton = styled(GradientButton)`
  align-self: flex-start;
  ${MediaQuery.tablet`
    width: 100%;
  `}
`

export const LeverageSlider = styled(Slider)`
  .MuiSlider-track {
    background: ${GradientColors.gradient1};
    height: 6px;
    border-width: 0;
  }

  .MuiSlider-thumb {
    background: ${GradientColors.gradient1};
    height: 30px;
    border-radius: 4px;
  }

  .MuiSlider-rail {
    background-color: ${({ theme }) => theme.palette.veryDarkGrayishBlue};
    opacity: 1;
  }
`

export const SliderWrapper = styled(Box)`
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 12px;

  ${MediaQuery.tablet`
    flex: none;
    margin: 32px 0;
  `}
`

import { useSyncExternalStore } from 'react'

import { MEDIA_WIDTHS } from '../utils/mediaQuery'

function onWindowSizeChange(onChange: () => void) {
  window.addEventListener('resize', onChange)
  return () => window.removeEventListener('resize', onChange)
}

function getWindowWidthSnapshot() {
  return window.innerWidth
}

function getWindowHeightSnapshot() {
  return window.innerHeight
}

export function useWindowDimensions() {
  const windowWidth = useSyncExternalStore(onWindowSizeChange, getWindowWidthSnapshot)

  const windowHeight = useSyncExternalStore(onWindowSizeChange, getWindowHeightSnapshot)

  return { windowWidth, windowHeight, isMobileDevice: windowWidth <= MEDIA_WIDTHS.tablet }
}

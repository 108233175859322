import { Flex } from 'rebass'
import styled from 'styled-components/macro'

const ShadowCard = styled(Flex).attrs({
  flexDirection: 'column',
})<{ gap?: string }>`
  box-shadow: 0px 4px 20px 0px rgba(40, 46, 63, 0.08);
  gap: ${({ gap }) => gap || '0px'};
`
export const GreyCard = styled(ShadowCard)<{ isInvalid?: boolean }>`
  border-radius: 24px;
  padding: 12px;
  box-shadow: none;
  width: 100%;
`

/* eslint-disable no-debugger */
import { Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { Box } from 'components'
import ValueLoading from 'components/ValueLoading'
import { ZERO_ADDRESS } from 'constants/misc'
import { IAppToken, IToken, useAccountTokens } from 'hooks/useAccountTokens'
import useAssetLogoSource from 'hooks/useAssetLogoSource'
import { useTrans } from 'i18n/useTransHook'
import { useNavigate } from 'react-router-dom'
import { SWAP_URL } from 'routes/paths'
import { formatDecimal } from 'utils/numberWithCommas'

import {
  BuyButton,
  CoinIcon,
  Container,
  MobileAssetWrapper,
  MobileDivider,
  PriceLabel,
  PriceValueLabel,
  Title,
  TokenItemMobileWrapper,
  TokenItemWrapper,
} from './styles'
import useTokens, { ITokens, ITokensFlexValues } from './useTokens'

interface ITokenItem {
  lastItem?: boolean
  item: IToken
  flexValues: ITokensFlexValues
  isMobileDevice: boolean
  limited: boolean
}
interface IAppItem {
  lastItem?: boolean
  item: IAppToken
  flexValues: ITokensFlexValues
  isMobileDevice: boolean
  limited: boolean
}

const TokenItem = ({ limited, item, lastItem = false, flexValues, isMobileDevice }: ITokenItem) => {
  const { address, name, balance, balanceUSD, symbol, price } = item
  const { chainId } = useWeb3React()
  const navigate = useNavigate()
  const { t } = useTrans('Portfolio')

  const isNative = address === ZERO_ADDRESS

  const [srcImg, handleNext] = useAssetLogoSource(address, chainId, isNative)

  console.log('balance', balance)
  if (isMobileDevice) {
    return (
      <TokenItemMobileWrapper $noDivider={lastItem}>
        <Box flexDirection="row" alignItems="center">
          {srcImg && <CoinIcon src={srcImg} handleError={handleNext} />}
          <MobileAssetWrapper>
            <Typography fontSize={16}>
              {name} {symbol ? <>({symbol})</> : ''}
            </Typography>
            {balance && (
              <Typography fontSize={14} color="lightGrayishBlue">
                {formatDecimal(balance, 4, 10)}
              </Typography>
            )}
          </MobileAssetWrapper>
          <Box flexDirection="column">
            {!limited && (
              <BuyButton
                onClick={() =>
                  isNative
                    ? navigate(`${SWAP_URL}?outputCurrency=${symbol}`)
                    : navigate(`${SWAP_URL}?outputCurrency=${address}`)
                }
              >
                {t('Buy')}
              </BuyButton>
            )}
            {!limited && (
              <BuyButton
                style={{ marginTop: '4px' }}
                onClick={() =>
                  isNative
                    ? navigate(`${SWAP_URL}?inputCurrency=${symbol}`)
                    : navigate(`${SWAP_URL}?inputCurrency=${address}`)
                }
                $isSell
              >
                {t('Sell')}
              </BuyButton>
            )}
          </Box>
        </Box>

        <Box flexDirection="row" mt={16}>
          <Box flex={1} alignItems="flex-end">
            <PriceLabel>{t('PRICE')}</PriceLabel>
            <PriceValueLabel>$ {formatDecimal(price, 2, 10)}</PriceValueLabel>
          </Box>

          <MobileDivider />
          <Box flex={1}>
            <PriceLabel>{t('VALUE')}</PriceLabel>
            <Box flexDirection="row" alignItems="center">
              <PriceValueLabel>$ {formatDecimal(balanceUSD, 2, 10)}</PriceValueLabel>
            </Box>
          </Box>
        </Box>
      </TokenItemMobileWrapper>
    )
  }

  return (
    <TokenItemWrapper $noDivider={lastItem}>
      <Box flexDirection="row" alignItems="center" flex={flexValues.assetFlex}>
        {srcImg && <CoinIcon src={srcImg} handleError={handleNext} />}
        <Typography fontSize={15} ml={12}>
          {name} {symbol ? <>({symbol})</> : ''}
        </Typography>
      </Box>

      <Typography flex={1} fontSize={15}>
        {price ? <>$ {formatDecimal(price, 2, 10)}</> : 'n/a'}
      </Typography>

      <Typography flex={1} fontSize={15}>
        {balance ? formatDecimal(balance, 4, 10) : 'n/a'}
      </Typography>

      <Box flexDirection="row" alignItems="center" flex={flexValues.valueFlex}>
        <Box flex={1}>
          <Typography fontSize={15}>$ {formatDecimal(balanceUSD, 2, 10)}</Typography>
        </Box>

        {!limited && (
          <BuyButton
            onClick={() =>
              isNative
                ? navigate(`${SWAP_URL}?outputCurrency=${symbol}`)
                : navigate(`${SWAP_URL}?outputCurrency=${address}`)
            }
          >
            {t('Buy')}
          </BuyButton>
        )}
        {!limited && (
          <BuyButton
            onClick={() =>
              isNative
                ? navigate(`${SWAP_URL}?inputCurrency=${symbol}`)
                : navigate(`${SWAP_URL}?inputCurrency=${address}`)
            }
            $isSell
          >
            {t('Sell')}
          </BuyButton>
        )}
      </Box>
    </TokenItemWrapper>
  )
}

const AppItem = ({ item, lastItem = false, flexValues, isMobileDevice }: IAppItem) => {
  const { appName, appImage, balanceUSD } = item
  const { t } = useTrans('Portfolio')

  if (isMobileDevice) {
    return (
      <TokenItemMobileWrapper $noDivider={lastItem}>
        <Box flexDirection="row" alignItems="center">
          {appImage && <CoinIcon src={appImage} />}
          <MobileAssetWrapper>
            <Typography fontSize={16}>{appName}</Typography>
          </MobileAssetWrapper>
        </Box>

        <Box flexDirection="row" mt={16}>
          <Box flex={1}>
            <PriceLabel>{t('VALUE')}</PriceLabel>
            <Box flexDirection="row" alignItems="center">
              <PriceValueLabel>$ {formatDecimal(balanceUSD, 2, 10)}</PriceValueLabel>
            </Box>
          </Box>
        </Box>
      </TokenItemMobileWrapper>
    )
  }

  return (
    <TokenItemWrapper $noDivider={lastItem}>
      <Box flexDirection="row" alignItems="center" flex={flexValues.assetFlex}>
        {appImage && <CoinIcon src={appImage} />}
        <Typography fontSize={15} ml={12}>
          {appName}
        </Typography>
      </Box>

      <Box flexDirection="row" alignItems="center" flex={flexValues.valueFlex}>
        <Box flex={1}>
          <Typography fontSize={15}>$ {formatDecimal(balanceUSD, 2, 10)}</Typography>
        </Box>
      </Box>
    </TokenItemWrapper>
  )
}
/**
 * Portfolio Tokens List
 * @constructor
 */
const Tokens = (props: ITokens) => {
  const { flexValues, isMobileDevice } = useTokens(props)
  const { t } = useTrans('Portfolio')

  const { chainId } = useWeb3React()

  const { data, isLoading } = useAccountTokens(chainId)

  return (
    <Container>
      <Box flexDirection="column">
        <Box flexDirection="row" alignItems="center">
          <Typography fontSize={33}>{t('Net Worth')}</Typography>

          <ValueLoading loading={isLoading}>
            <Typography ml={18} fontSize={24}>
              $ {formatDecimal(data?.value?.marketValue, 2, 10)}
            </Typography>
          </ValueLoading>
        </Box>
        <Typography mt={13} fontSize={18} color="lightGrayishBlue">
          {t('UPDATE_DELAY')}
        </Typography>
      </Box>

      <>
        {!isMobileDevice && (
          <Title>
            <Typography fontSize={13} color="lightGrayishBlue" flex={flexValues.assetFlex}>
              {t('ASSET')}
            </Typography>
            <Typography fontSize={13} color="lightGrayishBlue" flex={1}>
              {t('PRICE')}
            </Typography>
            <Typography fontSize={13} color="lightGrayishBlue" flex={1}>
              {t('BALANCE')}
            </Typography>
            <Typography fontSize={13} color="lightGrayishBlue" flex={flexValues.valueFlex}>
              {t('VALUE')}
            </Typography>
          </Title>
        )}

        {data?.tokenBalances?.map((item, index) => (
          <TokenItem
            key={item.address}
            lastItem={data.tokenBalances?.length - 1 === index}
            flexValues={flexValues}
            limited={!!props.limited}
            isMobileDevice={isMobileDevice}
            item={item}
          />
        ))}
      </>

      {data?.appBalances && data?.appBalances.length > 0 && (
        <>
          <Box mt="24px" flexDirection="row" alignItems="center">
            <Typography fontSize={24}>{t('Balance in Apps')}</Typography>
          </Box>

          {!isMobileDevice && (
            <Title>
              <Typography fontSize={13} color="lightGrayishBlue" flex={flexValues.assetFlex}>
                {t('ASSET')}
              </Typography>

              <Typography fontSize={13} color="lightGrayishBlue" flex={flexValues.valueFlex}>
                {t('VALUE')}
              </Typography>
            </Title>
          )}

          {data?.appBalances?.map((item, index) => (
            <AppItem
              key={item.address}
              lastItem={data.tokenBalances?.length - 1 === index}
              flexValues={flexValues}
              limited={!!props.limited}
              isMobileDevice={isMobileDevice}
              item={item}
            />
          ))}
        </>
      )}
    </Container>
  )
}

export default Tokens

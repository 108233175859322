import { defaultAbiCoder as AbiCoder } from '@ethersproject/abi'
import { Interface } from '@ethersproject/abi'
import UNIVERSAL_ROUTER_ABI from 'abis/blackbunny/universal-router.json'

enum Commands {
  '00' = 'V3_SWAP_EXACT_IN',
  '01' = 'V3_SWAP_EXACT_OUT',
  '02' = 'PERMIT2_TRANSFER_FROM',
  '0a' = 'PERMIT2_PERMIT',
  '0b' = 'WRAP_ETH',
  '0c' = 'UNWRAP_ETH',
  'V3_SWAP_EXACT_IN' = '00',
  'V3_SWAP_EXACT_OUT' = '01',
  'PERMIT2_TRANSFER_FROM' = '02',
  'PERMIT2_PERMIT' = '0a',
  'WRAP_ETH' = '0b',
  'UNWRAP_ETH' = '0c',
}

type CommandsToTypes = { [key in Commands]: string[] }

export interface ISwapProps {
  commands: string
  inputs: string[]
}

export async function subtractFeeInDataOfRawTx(account: string, rawTxData: string): Promise<ISwapProps> {
  const { commands, inputs } = await decodeSwapParams(rawTxData)
  const newParams = await subtractFeeInParams(account, commands, inputs)

  // const newTxData = await encodeSwapParams(newParams.commands, newParams.inputs)

  return newParams
}

// @ts-ignore

const commandsToTypes: CommandsToTypes = {
  [Commands.V3_SWAP_EXACT_IN]: ['address', 'uint256', 'uint256', 'bytes', 'bool'],
  [Commands.V3_SWAP_EXACT_OUT]: ['address', 'uint256', 'uint256', 'bytes', 'bool'],
  [Commands.PERMIT2_TRANSFER_FROM]: ['address', 'address', 'uint256'],
  [Commands.PERMIT2_PERMIT]: ['bytes', 'uint256'],
  [Commands.WRAP_ETH]: ['address', 'uint256'],
  [Commands.UNWRAP_ETH]: ['address', 'uint256'],
  [Commands['00']]: ['address', 'uint256', 'uint256', 'bytes', 'bool'],
  [Commands['01']]: ['address', 'uint256', 'uint256', 'bytes', 'bool'],
  [Commands['02']]: ['address', 'address', 'uint256'],
  [Commands['0a']]: ['bytes', 'uint256'],
  [Commands['0b']]: ['address', 'uint256'],
  [Commands['0c']]: ['address', 'uint256'],
}

async function subtractFeeInParams(account: string, commands: string, inputs: string[]) {
  const user = account
  const MSG_SENDER = '0x0000000000000000000000000000000000000001' // constant for msg.sender on Router contract

  for (let i = 2; i <= commands.length - 2; i += 2) {
    const command = commands.slice(i, i + 2) as Commands

    const index = i / 2 - 1

    if (command === Commands.PERMIT2_PERMIT) {
      commands = (commands as string).replace('0a', '')
      ;(inputs as string[]).splice(index, 1)
      // Return index to previous position after changing commands.length
      i -= 2

      continue
    }

    const decodedSwapParams = AbiCoder.decode(commandsToTypes[command], inputs[index])
    console.log(decodedSwapParams)

    if (command === Commands.V3_SWAP_EXACT_IN || command === Commands.V3_SWAP_EXACT_OUT) {
      // eslint-disable-next-line prefer-const
      let [recipient, amountIn, amountOut, path, fromUserFlag] = decodedSwapParams

      // Make user a recipient insted of msg.sender (SwapProxy)
      if (recipient === MSG_SENDER) {
        recipient = user
      }

      amountIn = (amountIn - Math.floor((amountIn * 100) / 10000)).toString()
      amountOut = (amountOut - Math.floor((amountOut * 100) / 10000)).toString()

      const newInput = AbiCoder.encode(commandsToTypes.V3_SWAP_EXACT_IN, [
        recipient,
        amountIn,
        amountOut,
        path,
        fromUserFlag,
      ])

      inputs[index] = newInput
    } else if (command === Commands.WRAP_ETH || command === Commands.UNWRAP_ETH) {
      // eslint-disable-next-line prefer-const
      let [recipient, amount] = decodedSwapParams

      // Make user a recipient insted of msg.sender (SwapProxy)
      if (recipient === MSG_SENDER) {
        recipient = user
      }

      amount = (amount - Math.floor((amount * 100) / 10000)).toString()

      const newInput = AbiCoder.encode(commandsToTypes.WRAP_ETH, [recipient, amount])
      inputs[index] = newInput
    }

    // const newDecodedSwapParams = AbiCoder.decode(commandsToTypes[command], inputs[index])
  }
  // const decodedTx = abi.decode(['bytes', 'address', 'uint256', 'uint256', 'bytes', 'bool', 'uint256'], rawTxData);

  return { commands, inputs }
}

const UNIVERSAL_ROUTER_INTERFACE = new Interface(UNIVERSAL_ROUTER_ABI)

async function decodeSwapParams(rawTxData: string) {
  const decodedTx = UNIVERSAL_ROUTER_INTERFACE.decodeFunctionData('execute(bytes,bytes[],uint256)', rawTxData)

  const [commands, inputs, deadline] = JSON.parse(JSON.stringify(decodedTx))
  return { commands, inputs, deadline }
}

import { Typography } from '@mui/material'
import { useTrans } from 'i18n/useTransHook'
import SwapPage from 'pages/Swap'

import { Container, Content } from './styles'

/**
 * Swap Page
 * @constructor
 */
const Swap = () => {
  const { t } = useTrans('SwapPage')

  return (
    <Container>
      <Typography fontSize={33}>{t('Swap')}</Typography>
      <Typography mt={13} fontSize={18} color="lightGrayishBlue">
        {t('Buy and Sell tokens instantly and HODL till they rise in value')}
      </Typography>

      <Content>
        <SwapPage />
      </Content>
    </Container>
  )
}

export default Swap

import { Typography } from '@mui/material'
import { Box, Button, GradientButton, PanelBox } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(PanelBox)`
  padding: 25px 30px;

  ${MediaQuery.screen`
    padding: 20px 10px;
  `};
  ${MediaQuery.tablet`
    padding: 25px 16px;
  `};
`

export const Title = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 40px 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.veryDarkGrayishBlue};
`

export const StakeButton = styled(Button).attrs({
  variant: 'outlined',
})<{ isUnstake?: boolean }>`
  border: 1px solid ${({ isUnstake }) => (isUnstake ? '#FF8399' : '#D4D9FF')};
  border-radius: 60px;
  color: ${({ isUnstake }) => (isUnstake ? '#FF8399' : '#D4D9FF')};
  height: 33px;
  padding: 0 15px;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    border-color: ${({ isUnstake }) => (isUnstake ? '#FF8399' : '#D4D9FF')};
  }

  &.Mui-disabled {
    border-color: ${({ isUnstake }) => (isUnstake ? '#FF8399' : '#D4D9FF')};
    color: ${({ isUnstake }) => (isUnstake ? '#FF8399' : '#D4D9FF')};
    opacity: 0.4;
  }
`

export const TokenItemWrapper = styled(Box)<{ $noDivider: boolean }>`
  flex-direction: row;
  align-items: center;
  height: 65px;
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}
`

export const SeeMoreButton = styled(GradientButton)`
  margin-top: 20px;
  align-self: flex-start;

  ${MediaQuery.screen`
    width: 100%;
  `}
`

export const MobileTokenItemWrapper = styled(Box)<{ $noDivider: boolean }>`
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}
  padding-top: 16px;
`

export const MobileAssetWrapper = styled(Box)`
  flex: 1;
  margin-left: 12px;
  p:first-child {
    ${MediaQuery.tablet`
        font-size: 18px;
    `}
  }
  p:last-child {
    ${MediaQuery.tablet`
        font-size: 16px;
    `}
  }
`

export const WalletButton = styled(GradientButton).attrs(({ theme }) => ({
  variant: 'outlined',
  bgColor: theme.palette.veryDarkDesaturatedBlue,
}))`
  min-width: 48px;
  height: 48px;
  border-radius: 12px;
`

export const MobileInfoWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  overflow-x: auto;
  padding: 0 16px 16px 0;

  p {
    white-space: nowrap;
  }
`

export const PriceLabel = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};
  ${MediaQuery.phone`
    font-size: 12px;
  `}
`

export const PriceValueLabel = styled(Typography)`
  display: flex;
  &,
  p {
    font-size: 16px;
  }
  ${MediaQuery.phone`
    &, p {
      font-size: 14px;
    }
  `}
`

export const MobileDivider = styled(Box)`
  min-width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.veryDarkGrayishBlue};
  margin: 0 16px;

  ${MediaQuery.phone`
    margin: 0 8px;
  `}
`

import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { t } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PopulatedTransaction } from 'ethers'
import { useCallback, useMemo, useState } from 'react'
import { useAllTransactions, useTransactionAdder } from 'state/transactions/hooks'
import { TransactionDetails, TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import usePrevious from './usePrevious'

type AsyncFunc = () => Promise<PopulatedTransaction | undefined>

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`)
  }
}

const isTxPending = (tx: TransactionDetails) => !tx.receipt
function wasPending(previousTxs: { [hash: string]: TransactionDetails | undefined }, current: TransactionDetails) {
  const previousTx = previousTxs[current.hash]
  return previousTx && isTxPending(previousTx)
}

function useHasPendingTx(key: string) {
  // TODO: consider monitoring tx's on chains other than the wallet's current chain
  const currentChainTxs = useAllTransactions()

  // monitor the status of the claim from contracts and txns
  const pendingTxs = useMemo(() => {
    return Object.entries(currentChainTxs).reduce((acc: { [hash: string]: TransactionDetails }, [hash, tx]) => {
      if (!tx.receipt) acc[hash] = tx
      return acc
    }, {})
  }, [currentChainTxs])

  const previousPendingTxs = usePrevious(pendingTxs)

  return useMemo(() => {
    if (!previousPendingTxs) return false
    return Object.values(currentChainTxs).some(
      (tx) => !isTxPending(tx) && wasPending(previousPendingTxs, tx) && tx.actionType === key,
      [currentChainTxs, previousPendingTxs],
    )
  }, [currentChainTxs, previousPendingTxs, key])
}

export const useTxTemplate = (
  type: TransactionType,
  contentSuccess: string,
  funcTxData: AsyncFunc,
  key: string,
  txCallback?: (tx: TransactionResponse) => void,
) => {
  const { account, chainId, provider } = useWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `${type}_${key}`

  const pending = useHasPendingTx(actionType)

  const [disabled, setDisabled] = useState(false)

  const action = useCallback(async () => {
    if (!chainId || !provider || !account) return

    if (account) {
      const txData = await funcTxData()

      const txn = {
        ...txData,
        value: txData?.value || '0x0',
      }

      let gasEstimate: BigNumber
      try {
        gasEstimate = await provider.estimateGas(txn)
      } catch (gasError) {
        console.warn(gasError)
        throw new GasEstimationError()
      }
      const gasLimit = calculateGasMargin(gasEstimate)

      try {
        const newTxn = {
          ...txn,
          gasLimit,
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            txCallback && txCallback(response)
            // @ts-ignore
            addTransaction(response, { type, content: contentSuccess, actionType })
          })
      } catch (error) {
        console.error('Failed to send transaction', error)

        setDisabled(true)

        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      }
    } else {
      return
    }
  }, [funcTxData, account, actionType, contentSuccess, type, addTransaction, chainId, txCallback, provider])

  return {
    action,
    disabled,
    pending,
  }
}

/**
 */

import { Contract } from '@ethersproject/contracts'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'

export const useBalance = (contract: Contract | null, address: string | null | undefined) => {
  const deps = useMemo(() => [address || undefined], [address])
  return useSingleCallResult(contract, 'balanceOf', deps)
}

const DEFAULT: string[] = []

export const useDecimals = (contract: Contract | null): number => {
  const val = useSingleCallResult(contract, 'decimals', DEFAULT)?.result?.[0]
  return useMemo(() => val || 0, [val])
}

import { Box } from '@mui/material'
import { IRefCodeRegistered } from 'api'
import { useIsMobile, useIsTablet } from 'nft/hooks'

import { InfiniteScrollListStyled } from '../styles'
import { CodesItem } from './CodesItem'

export const CodesList = ({ data }: { data: IRefCodeRegistered[] }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const isMobileOrTablet = isMobile || isTablet

  return (
    <Box mt={isMobileOrTablet ? 8 : 24}>
      <InfiniteScrollListStyled
        itemsTotal={data}
        renderItem={(item: any, index: number) => (
          <CodesItem data={item} key={index} isLast={index === data.length - 1} />
        )}
      />
    </Box>
  )
}

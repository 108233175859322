import { useState } from 'react'

export enum PortfolioTabs {
  TOKENS = 'TOKENS',
  HISTORY = 'HISTORY',
}

/**
 * Portfolio Page Logic
 */
const usePortfolio = () => {
  const [activeTab, setActiveTab] = useState<PortfolioTabs>(PortfolioTabs.TOKENS)

  return {
    activeTab,
    setActiveTab,
  }
}

export default usePortfolio

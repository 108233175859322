export default [
  {
    type: 'Delegated',
    time: '24 Jan',
    amount: '- 0.759 USDC',
  },
  {
    type: 'Received',
    time: '15 Jan',
    amount: '+ 0.759 USDC',
  },
  {
    type: 'Received',
    time: '15 Jan',
    amount: '+ 0.759 USDC',
  },
  {
    type: 'Delegated',
    time: '4 Jan',
    amount: '- 0.759 USDC',
  },
  {
    type: 'Delegated',
    time: '4 Jan',
    amount: '- 0.759 USDC',
  },
]

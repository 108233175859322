import { Box, Typography } from '@mui/material'
import { IToken } from 'api'
import { useScreenSize } from 'hooks/useScreenSize'
import { useTranslation } from 'react-i18next'

import { Cell, InfiniteScrollListStyled, Row } from '../styles'
import { AccrualsItem } from './AccrualsItem'
import { NoAccruals } from './NoAccruals'

export const AccrualsList = ({ data }: { data: IToken[] }) => {
  const isMobile = !useScreenSize()['md']
  const { t } = useTranslation('Referrals')

  if (data?.length <= 0) return <NoAccruals />

  return (
    <Box mt={isMobile ? 0 : 24}>
      {!isMobile && (
        <Row
          columns={2}
          sx={{
            paddingBottom: 10,
          }}
        >
          <Cell>
            <Typography fontSize={14} color="lightGrayishBlue" textTransform="uppercase">
              {t('Token')}
            </Typography>
          </Cell>
          <Cell>
            <Typography fontSize={14} color="lightGrayishBlue" textTransform="uppercase">
              {t('Collected')}
            </Typography>
          </Cell>
        </Row>
      )}
      <InfiniteScrollListStyled
        itemsTotal={data}
        renderItem={(item: any, index: number) => (
          <AccrualsItem data={item} key={index} isLast={index === data.length - 1} />
        )}
      />
    </Box>
  )
}

import { Stack, Typography } from '@mui/material'
import { IRefCodeRegistered, useReferralRewardAccrueds } from 'api'
import { Box } from 'components'
import { useActiveWeb3React, useScreenSize } from 'hooks'
import { useIsMobile, useIsTablet } from 'nft/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BN_1E16 } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import CopyIcon from '../../../assets/icons/copy.svg'
import PPlaneIcon from '../../../assets/icons/pplane.svg'
import Popover from '../../../components/Popover'
import { useGetRefCodeAccruals } from '../hooks'
import { Cell, IconButton, Row } from '../styles'

async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text)
}

async function share(url: string, text?: string) {
  try {
    await navigator.share({
      title: 'Referral code',
      text,
      url,
    })
  } catch (error) {
    console.error('Error sharing', error)
    await copyToClipboard(url)
  }
}

const CopyBtn = ({
  textOrUrl,
  sharedDesc,
  icon,
  isShareBtn,
}: {
  textOrUrl: string
  sharedDesc?: string
  icon: string
  isShareBtn?: boolean
}) => {
  const [show, setShow] = useState(false)

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const [clicked, setClicked] = useState(false)

  const isMobileOrTablet = isMobile || isTablet

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }

    return () => null
  }, [show])

  async function handleClick() {
    if (clicked) return

    if (isShareBtn) {
      await share(textOrUrl, sharedDesc)
      setShow(true)
      return
    }
    await copyToClipboard(textOrUrl)
    setShow(true)
  }

  return (
    <Popover
      show={show}
      placement="bottom"
      style={{
        height: isMobileOrTablet ? 24 : 22,
      }}
      content={
        <Box
          sx={{
            padding: '4px 12px',
            borderRadius: 8,
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          Copied!
        </Box>
      }
    >
      <IconButton
        onTouchStart={() => setClicked(true)}
        onMouseDown={() => setClicked(true)}
        onTouchEnd={() => setClicked(false)}
        onMouseUp={() => setClicked(false)}
        onMouseLeave={() => setClicked(false)}
        style={{
          opacity: clicked ? 0.5 : 1,
        }}
        onClick={handleClick}
      >
        <img src={icon} />
      </IconButton>
    </Popover>
  )
}

export const CodesItem = ({ data, isLast }: { data: IRefCodeRegistered; isLast: boolean }) => {
  const { t } = useTranslation('Referrals')

  const { chainId } = useActiveWeb3React()

  const isScreenSize = useScreenSize()
  const isMobile = !isScreenSize['md']
  const isTablet = useIsTablet()

  const { list: rewards } = useReferralRewardAccrueds({ hash: data.referralCodeHash })

  const isMobileOrTablet = isMobile || isTablet

  const totalAccrual = useGetRefCodeAccruals(rewards)

  const rewardsByLastMonth = rewards.filter(
    (reward) => new Date(reward.timestamp).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000,
  )

  const accrualsByLastMonth = useGetRefCodeAccruals(rewardsByLastMonth)

  const referredUsers = useMemo(() => {
    const uniqueUsers = new Set(rewards.map((reward) => reward.executor.address))
    return uniqueUsers.size
  }, [rewards])

  return (
    <Row
      columns={2}
      sx={{
        paddingY: 16,
        alignItems: 'stretch',
        borderBottom: isLast ? 'unset' : '',
      }}
      style={{
        borderBottom: isLast ? 'unset' : '',
        paddingBottom: isLast ? 0 : '',
      }}
    >
      <Cell
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: 'space-between',
          width: isMobileOrTablet ? '100%' : 'auto',
          marginBottom: isMobile ? 16 : 0,
          gap: 8,
          flex: 2,
        }}
      >
        <Typography fontSize={16}>{data.referralCode}</Typography>
        <Stack direction="row" alignItems="flex-start" columnGap={6}>
          <CopyBtn
            textOrUrl={`https://blackbunny.app?refCode=${data.referralCode}&network=${chainId}`}
            icon={CopyIcon}
          />
          <CopyBtn
            textOrUrl={`https://blackbunny.app?refCode=${data.referralCode}&network=${chainId}`}
            sharedDesc={data.referralCode}
            isShareBtn
            icon={PPlaneIcon}
          />
        </Stack>
      </Cell>
      <Cell
        display="flex"
        minWidth="max-content"
        width={isMobileOrTablet ? '100%' : 'auto'}
        direction={isMobile ? 'row' : 'column'}
        justifyContent="space-between"
        marginBottom={isMobileOrTablet ? 4 : 0}
      >
        <Typography color="lightGrayishBlue" fontSize={16}>
          {t('Monthly Volume')}
        </Typography>
        <Typography fontSize={16} lineHeight={1.5}>
          {accrualsByLastMonth.val.isZero()
            ? '$0'
            : accrualsByLastMonth.isLoading
            ? '...'
            : accrualsByLastMonth.val.lte(BN_1E16.toString())
            ? '<0.1$'
            : formatDecimal(accrualsByLastMonth.val)}
        </Typography>
      </Cell>
      <Cell
        display="flex"
        width={isMobileOrTablet ? '100%' : 'auto'}
        direction={isMobile ? 'row' : 'column'}
        justifyContent="space-between"
        marginBottom={isMobileOrTablet ? 4 : 0}
      >
        <Typography color="lightGrayishBlue" fontSize={16}>
          {t('Users Referred')}
        </Typography>
        <Typography fontSize={16} lineHeight={1.5}>
          {referredUsers}
        </Typography>
      </Cell>
      <Cell
        display="flex"
        width={isMobileOrTablet ? '100%' : 'auto'}
        direction={isMobile ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <Typography color="lightGrayishBlue" fontSize={16}>
          {t('Accruals')}
        </Typography>
        <Typography fontSize={16} lineHeight={1.5}>
          {totalAccrual.val.isZero()
            ? '$0'
            : totalAccrual.isLoading
            ? '...'
            : totalAccrual.val.lte(BN_1E16.toString())
            ? '<0.1$'
            : '$' + formatDecimal(totalAccrual.val)}
        </Typography>
      </Cell>
    </Row>
  )
}

import { USDT_MAINNET } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import DELEGATION_PROXY from 'abis/blackbunny/delegation-proxy.json'
import REFERRALS_ABI from 'abis/blackbunny/referrals.json'
import SWAP_PROXY_ABI from 'abis/blackbunny/swap-proxy.json'
import MASTERCHEF_ABI from 'abis/blackbunny/vault-masterchef.json'
import MASTERCHEF_V3_ABI from 'abis/blackbunny/vault-masterchef-v3.json'
import VAULT_PROXY_V2 from 'abis/blackbunny/vault-proxy-v2.json'
import VAULT_PROXY_V3 from 'abis/blackbunny/vault-proxy-v3.json'
import ERC20Abi from 'abis/erc20.json'
import { DelegationProxy, Erc20, Referrals, SwapProxy, VaultProxyV2, VaultProxyV3 } from 'abis/types'
import { SupportedChainId } from 'constants/chains'
import { USDT_POLYGON } from 'constants/tokens'
import { useContract } from 'hooks/useContract'
import { useMemo } from 'react'

const SWAP_MAINNET = '0x022A128aCB51f82A04E1D815ed6b45095C1F42e9'
const SWAP_POLYGON = '0x2fC51f22E58e1C20A9c1e6d4758D0513548d401e'

const DELEGATION_MAINNET = '0x9b6FC9b8Bb209569aE5643a562b43e4760a41926'
const DELEGATION_POLYGON = '0x1511B1D229775Bd343bEbC4cab93B33783d299a7'

const VAULT_PROXY_POLYGON_V2 = '0x8f4a2814b2fC65d16E5427E2616d153f09FbE045'
const VAULT_PROXY_POLYGON_V3 = '0xEE0ED0d6492C20B077990AA242A9afceFb17C166'

const VAULT_PROXY_MAINNET_V2 = '0xCBc6195d5A7Dc1177fFC47Ef9e3bCff5b1D7DE30'
const VAULT_PROXY_MAINNET_V3 = '0xB00e656bA042315fA20e89c4d0b6A63D16c06C31'

const REFERRALS_MAINNET = '0xe8E0a03501a4e067AB3d1Df94Ed7BbFb69A0bAD7'
const REFERRALS_POLYGON = '0x24802EFDBFB5E4AFa9c37bA45B22ad5C7220105C'

const getSourceByNetwork = (chainId: number | undefined, mainnet = '', polygon = ''): string => {
  if (chainId === SupportedChainId.MAINNET) {
    return mainnet
  } else if (chainId === SupportedChainId.POLYGON) {
    return polygon
  }

  return ''
}

const useAddressChain = (mainnet = '', polygon = '') => {
  const { chainId } = useWeb3React()

  const address = useMemo(() => {
    return getSourceByNetwork(chainId, mainnet, polygon)
  }, [chainId, mainnet, polygon])

  return address
}

export const useVaultAddress = (isV3: boolean) => {
  return useAddressChain(
    isV3 ? VAULT_PROXY_MAINNET_V3 : VAULT_PROXY_MAINNET_V2,
    isV3 ? VAULT_PROXY_POLYGON_V3 : VAULT_PROXY_POLYGON_V2,
  )
}

const useUsdtAddress = () => {
  return useAddressChain(USDT_MAINNET.address, USDT_POLYGON.address)
}

export const useDelegationAddress = () => {
  return useAddressChain(DELEGATION_MAINNET, DELEGATION_POLYGON)
}

const useReferralsContractAddress = () => {
  return useAddressChain(REFERRALS_MAINNET, REFERRALS_POLYGON)
}

export const useReferralsContract = () => {
  const address = useReferralsContractAddress()
  return useContract<Referrals>(address, REFERRALS_ABI, true)
}

export const useDelegationContract = () => {
  const address = useDelegationAddress()
  return useContract<DelegationProxy>(address, DELEGATION_PROXY, true)
}

export const useUsdtContract = () => {
  const a = useUsdtAddress()
  return useContract<Erc20>(a, ERC20Abi, true)
}

export const useErc20Contract = (address?: string) => {
  return useContract<Erc20>(address, ERC20Abi, true)
}

export const useVaultContract = (isV3: boolean) => {
  const address = useVaultAddress(isV3)
  const v3 = useContract<VaultProxyV3>(address, VAULT_PROXY_V3, true)
  const v2 = useContract<VaultProxyV2>(address, VAULT_PROXY_V2, true)

  return isV3 ? v3 : v2
}

export const useVaultMasterchefV2 = (address: string) => {
  return useContract(address, MASTERCHEF_ABI, true)
}

export const useVaultMasterchefV3 = (address: string) => {
  return useContract(address, MASTERCHEF_V3_ABI, true)
}

export const useBbSwapProxyAddress = () => {
  return useAddressChain(SWAP_MAINNET, SWAP_POLYGON)
}
export const useBbSwapProxyContract = () => {
  const address = useBbSwapProxyAddress()
  return useContract<SwapProxy>(address, SWAP_PROXY_ABI, true)
}

import { Box, Button } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  width: 270px;
  background-color: ${({ theme }) => theme.palette.veryDarkDesaturatedBlue};
  border-radius: 0px 40px 40px 0px;
  overflow: hidden;

  ${MediaQuery.extraLarge`
    width: 240px;
  `};
`

export const NavButtonBase = styled(Button).attrs({
  variant: 'text',
})<{ $highlighted: boolean; disabled?: boolean }>`
  padding: 0 20px;
  height: 50px;
  margin: 8px 12px 0;
  border-radius: 25px;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ $highlighted }) => $highlighted && `background-color: rgba(183, 188, 221, 0.1);`}
`

export const NavIcon = styled.img`
  width: 22px;
  margin-right: 13px;
`

export const ProfileBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin: 35px 0 20px 32px;
`

import gql from 'graphql-tag'
import { useActiveWeb3React } from 'hooks'
import { useMemo } from 'react'

import { useApiBlockNumber, useApiQuery, useSWR } from './graphql'

const query = gql`
  query referralCodeRegistereds($address: String, $chainId: BigInt) {
    referralCodeRegistereds(where: { referrer: { address_eq: $address }, chainId_eq: $chainId }) {
      id
      referralCode
      referralCodeHash
      superiorReferralCodeHash
      timestamp
      transactionHash
    }
  }
`

export interface IRefCodeRegistered {
  id: string
  referralCode: string
  referralCodeHash: string
  superiorReferralCodeHash: string
  timestamp: number
  transactionHash: string
}

export function useClaimedRewardByAddress({ address }: { address?: string }): {
  list: IRefCodeRegistered[]
  loading: boolean
  error: any
} {
  const block = useApiBlockNumber()

  const { chainId } = useActiveWeb3React()

  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (address) {
      vars.address = address.toLowerCase()
    }

    if (chainId) {
      vars.chainId = chainId
    }

    return vars
  }, [address, block, chainId])

  const fetchApi = useApiQuery(query, vars)
  const { data, error } = useSWR(`ReferralCodeRegisteredById${JSON.stringify(vars)}`, fetchApi)

  return {
    list: data ? data.referralCodeRegistereds : [],
    loading: !data,
    error,
  }
}

import { TabButton } from 'components'
import { useTrans } from 'i18n/useTransHook'

import History from './History'
import { Container, TabWrapper } from './styles'
import Tokens from './Tokens'
import usePortfolio, { PortfolioTabs } from './usePortfolio'

/**
 * Portfolio Page
 * @constructor
 */
const Portfolio = () => {
  const { activeTab, setActiveTab } = usePortfolio()
  const { t } = useTrans('Portfolio')
  return (
    <Container>
      <TabWrapper>
        <TabButton onClick={() => setActiveTab(PortfolioTabs.TOKENS)} $selected={activeTab === PortfolioTabs.TOKENS}>
          {t('Tokens')}
        </TabButton>
        <TabButton onClick={() => setActiveTab(PortfolioTabs.HISTORY)} $selected={activeTab === PortfolioTabs.HISTORY}>
          {t('History')}
        </TabButton>
      </TabWrapper>
      {activeTab === PortfolioTabs.TOKENS ? <Tokens /> : <History />}
    </Container>
  )
}

export default Portfolio

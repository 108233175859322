export const ColorsLight = {
  lightGrayishBlue: '#B7BCDD',
  lightCyan: '#4BFF9C',
  lightRed: '#FF4F5A',
  veryDarkDesaturatedBlue: '#2B2E49',
  veryDarkDesaturatedBlue2: '#41456C',
  veryPaleBlue: '#D4D9FF',
  mostlyDesaturatedDarkBlue: '#5D6290',
  veryDarkGrayishBlue: '#484768',
  verySoftBlue: '#ACB4FD',
}

export const ColorsBlack = {
  lightGrayishBlue: '#B7BCDD',
  lightCyan: '#4BFF9C',

  lightRed: '#FF4F5A',
  veryDarkDesaturatedBlue: '#2B2E49',
  veryDarkDesaturatedBlue2: '#41456C',
  veryPaleBlue: '#D4D9FF',

  mostlyDesaturatedDarkBlue: '#5D6290',
  veryDarkGrayishBlue: '#484768',
  verySoftBlue: '#ACB4FD',
}

export const GradientColors = {
  gradient1: `linear-gradient(90deg, #1a8cff 0%, #7d65fc 100%)`,
}

import { useState } from 'react'

/**
 * Delegate Page Logic
 */
const useDelegateView = () => {
  const [percentValue, setPercentValue] = useState(6)

  const handleChangePercent = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setPercentValue(newValue)
    }
  }

  return {
    percentValue,
    handleChangePercent,
  }
}

export default useDelegateView

import { css } from 'styled-components/macro'

export const MEDIA_WIDTHS = {
  phone: 480,
  tablet: 768,
  screen: 1024,
  large: 1200,
  extraLarge: 1440,
}

export const MediaQuery: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator: any, size) => {
    accumulator[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {},
)

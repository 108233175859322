import { BigNumber } from '@ethersproject/bignumber'

import { BN_1E18 } from './isZero'

const ROUNDER = 1e6

export const getBigNumberValue = (amount: string | number, decimals: number | string | BigNumber = BN_1E18) => {
  return BigNumber.from(Math.floor(+amount * ROUNDER))
    .mul(decimals)
    .div(ROUNDER)
}

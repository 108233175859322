import { useMemo, useState } from 'react'

import { coins, fiats } from './fixtures'

export interface IToken {
  symbol: string
  name: string
  icon?: string
}

export interface ITokenItem {
  token: IToken
  onClick: (value: IToken) => void
  lastItem: boolean
}

export enum ISelectTokenType {
  COIN = 'COIN',
  FIAT = 'FIAT',
}

export interface ISelectTokenDialog {
  open: boolean
  onChangeToken?: (value: IToken) => void
  onClose: () => void
  type?: ISelectTokenType
}

/**
 * Select Token Dialog Logic
 */
const useSelectTokenDlgHook = ({ type = ISelectTokenType.COIN }: ISelectTokenDialog) => {
  const [keyword, setKeyword] = useState('')
  const tokens = useMemo(
    () =>
      (type === ISelectTokenType.COIN ? coins : fiats).filter(
        (token) =>
          token.symbol.toLowerCase().includes(keyword.toLowerCase()) ||
          token.name.toLowerCase().includes(keyword.toLowerCase()),
      ),
    [keyword, type],
  )

  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  return {
    keyword,
    handleChangeKeyword,
    tokens,
  }
}

export default useSelectTokenDlgHook

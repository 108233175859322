import { Box, Button, ImageButton } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex-direction: row;
  align-items: flex-start;

  padding: 30px 70px 0;

  ${MediaQuery.extraLarge`
    padding: 20px 20px 0;
  `};
`

export const MobileHeaderContainer = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  min-height: 56px;
  background-color: ${({ theme }) => theme.palette.veryDarkDesaturatedBlue};
`

export const HamburgerButton = styled(Button)`
  min-width: 56px;
  height: 56px;
`

export const ProfileButton = styled(ImageButton).attrs({
  variant: 'text',
})`
  height: 56px;
  margin-right: 8px;
  border-radius: 28px;

  img {
    width: 32px;
    height: 32px;

    ${MediaQuery.phone`
      width: 32px;
      height: 32px;
    `};
  }
`

export const CTAWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;

  ${MediaQuery.large`
    display: none;
  `}
`

import { Box } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  background-color: #1a1c30;
  height: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

export const Content = styled(Box)`
  flex: 1;
  position: relative;
`

export const RoutesWrapper = styled(Box)`
  padding: 0 70px;
  flex: 1;

  ${MediaQuery.extraLarge`
    padding: 0 20px;
  `};
  ${MediaQuery.screen`
    padding: 0 10px;
  `};
  ${MediaQuery.tablet`
    padding: 0 16px;
  `};
`

import { Typography } from '@mui/material'
import clipboardIcon from 'assets/icons/clipboard.svg'
import { Box } from 'components'
import { useTrans } from 'i18n/useTransHook'
import { useModalIsOpen, useToggleModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

import { CancelButton, DialogContainer } from './styles'

/**
 * Contact Broker Dialog
 * @constructor
 */
const ContactBrokerDialog = () => {
  const open = useModalIsOpen(ApplicationModal.CONTACT_BROKER)
  const toggleModal = useToggleModal(ApplicationModal.CONTACT_BROKER)
  const { t } = useTrans('ContactDialog')

  return (
    <DialogContainer onClose={toggleModal} open={open}>
      <Box>
        <Typography fontSize={24}>{t('Contact the broker')}</Typography>
        <Typography mt={25}>{t('Please_contact')}</Typography>
        <Box flexDirection="row" alignItems="center" mt={20}>
          <Typography fontSize={16} color="verySoftBlue">
            {t('Phone')}
          </Typography>
          <Typography fontSize={16} ml={13} mr={9}>
            +7 777 777 77 77
          </Typography>
          <img src={clipboardIcon} width={18} />
        </Box>

        <Box flexDirection="row" alignItems="center" mt={13}>
          <Typography fontSize={16} color="verySoftBlue">
            E-mail
          </Typography>
          <Typography fontSize={16} ml={13} mr={9}>
            blackb@mail.com
          </Typography>
          <img src={clipboardIcon} width={18} />
        </Box>

        <CancelButton onClick={toggleModal}>{t('Cancel')}</CancelButton>
      </Box>
    </DialogContainer>
  )
}

export default ContactBrokerDialog

import { Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { Box, TypeBox } from 'components'
import { combineActivities, createGroups } from 'components/AccountDrawer/MiniPortfolio/Activity'
import { IAllowed, useLocalActivities } from 'components/AccountDrawer/MiniPortfolio/Activity/parseLocal'
import { useTimeSince } from 'components/AccountDrawer/MiniPortfolio/Activity/parseRemote'
import { Activity } from 'components/AccountDrawer/MiniPortfolio/Activity/types'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import AlertTriangleFilled from 'components/Icons/AlertTriangleFilled'
import { LoaderV2 } from 'components/Icons/LoadingSpinner'
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks'
import useENSName from 'hooks/useENSName'
import { useTrans } from 'i18n/useTransHook'
import { shortenTx } from 'nft/utils/address'
import { useMemo } from 'react'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import fixtures from './fixtures'
import { Banner, Container, Title, TokenItemWrapper } from './styles'

interface ITradeTokenItem {
  item: Activity
  lastItem: boolean
}

const StyledTimestamp = styled(ThemedText.Caption)`
  color: ${({ theme }) => theme.textSecondary};
  font-variant: small;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss02' on;
`

const TradeTokenItem = ({ item, lastItem }: ITradeTokenItem) => {
  const { chainId, status, descriptor, logos, otherAccount, title, currencies, timestamp, hash, receipt } = item
  const info = receipt?.info

  const { ENSName } = useENSName(otherAccount)
  const timeSince = useTimeSince(timestamp)

  const explorerUrl = getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)

  return (
    <TokenItemWrapper $noDivider={lastItem} onClick={() => window.open(explorerUrl, '_blank')}>
      <Box flexDirection="row" alignItems="center" flex={1.5}>
        <TypeBox>
          <PortfolioLogo chainId={chainId} currencies={currencies} images={logos} accountAddress={otherAccount} />
        </TypeBox>
        <Box
          ml={13}
          gap={status === TransactionStatus.Pending ? '4px' : undefined}
          flexDirection={status === TransactionStatus.Pending ? 'row' : 'column'}
        >
          <Typography fontSize={15}>{info?.content || `${descriptor || ''} ${title}`}</Typography>
          <Typography fontSize={14} color="lightGrayishBlue">
            {status === TransactionStatus.Pending ? (
              <LoaderV2 />
            ) : status === TransactionStatus.Confirmed ? (
              <StyledTimestamp>{timeSince}</StyledTimestamp>
            ) : (
              <AlertTriangleFilled />
            )}
          </Typography>
        </Box>
      </Box>

      <Typography fontSize={14} flex={1}>
        <Banner style={{ width: '120px' }}>{ENSName ?? otherAccount ?? shortenTx(hash, 5, 5)}</Banner>
      </Typography>
    </TokenItemWrapper>
  )
}

const ALLOWED: IAllowed = {
  [TransactionType.DELEGATE_TO_TRADE]: true,
}

/**
 * Trading List
 * @constructor
 */
const DelegatedList = ({ className }: { className?: string }) => {
  const { account = '' } = useWeb3React()
  const localMap = useLocalActivities(account, ALLOWED)

  const activityGroups = useMemo(() => {
    const allActivities = combineActivities(localMap, undefined, account)
    return createGroups(allActivities)
  }, [localMap, account])

  const { t } = useTrans('Delegate')

  return (
    <Container className={className}>
      <Box flexDirection="row" alignItems="center">
        <Typography fontSize={24}>{t('Delegation')}</Typography>
      </Box>

      <Title>
        <Typography fontSize={13} color="lightGrayishBlue" flex={1.5} textTransform="uppercase">
          {t('Info')}
        </Typography>
        <Typography fontSize={13} color="lightGrayishBlue" flex={1} textTransform="uppercase">
          {t('Transaction')}
        </Typography>
      </Title>

      {activityGroups.map(({ title, transactions }) => (
        <Box key={title} mt={35}>
          <Typography fontSize={15} color="veryPaleBlue">
            {title}
          </Typography>
          {transactions.map((item, index) => (
            <TradeTokenItem key={index} lastItem={fixtures.length - 1 === index} item={item} />
          ))}
        </Box>
      ))}
    </Container>
  )
}

export default DelegatedList

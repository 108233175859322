import { Box } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex: 1;
  margin: 40px 0;

  ${MediaQuery.tablet`
    margin-top: 20px;
  `}
`

import { Dialog } from '@mui/material'
import { GradientButton } from 'components'
import styled from 'styled-components/macro'

export const DialogContainer = styled(Dialog)`
  .MuiPaper-root {
    padding: 30px 40px;
    max-width: 550px;
    width: 100%;
  }
`

export const CancelButton = styled(GradientButton)`
  margin-top: 30px;
  align-self: flex-end;
`

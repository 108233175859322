import { BigNumber } from '@ethersproject/bignumber'

import { fromWei } from './fromWei'

function numberWithCommas(x: string) {
  // Split the number into integer and decimal parts
  const parts = x.split('.')
  // Insert commas into the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // Join the integer and decimal parts back together
  return parts.join('.')
}

// eslint-disable-next-line import/no-unused-modules
export const formatDecimal = (value: BigNumber | number | string, precision = 2, decimals = 18) => {
  if (!value) {
    return '0'
  }

  const data = typeof value === 'object' ? +fromWei(value, decimals) : +value
  const fixedData = data.toFixed(precision)

  if (data >= 1) {
    return numberWithCommas(fixedData)
  }

  return fixedData
}

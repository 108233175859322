import { Button } from 'components'
import styled from 'styled-components/macro'

export const CoinButton = styled(Button).attrs({
  variant: 'text',
})`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 120px;
  justify-content: flex-start;
  border-left: 1px solid ${({ theme }) => theme.palette.mostlyDesaturatedDarkBlue};
  padding-left: 13px;
  padding-right: 12px;
`

export const SUPPORTED_LOCALES = [
  // order as they appear in the language dropdown
  'en-US',
  'ru-RU',
]
export type SupportedLocale = typeof SUPPORTED_LOCALES[number] | 'pseudo'

export const DEFAULT_LOCALE: SupportedLocale = 'en-US'

export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  'en-US': 'English',
  'ru-RU': 'Русский',
}

export const SHORT_LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  'en-US': 'EN',
  'ru-RU': 'RU',
}

import { Typography } from '@mui/material'
import { Box, Button, PanelBox } from 'components'
import LogoIcon from 'components/LogoIcon/LogoIcon'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(PanelBox)`
  padding: 25px 30px;

  ${MediaQuery.screen`
    padding: 20px 10px;
  `};
  ${MediaQuery.tablet`
    padding: 25px 16px;
  `};
`

export const Title = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 20px 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.veryDarkGrayishBlue};
`

export const BuyButton = styled(Button).attrs({
  variant: 'outlined',
})<{ $isSell?: boolean }>`
  padding: 0 20px;
  border: 1px solid ${({ $isSell }) => ($isSell ? '#FF8399' : '#D4D9FF')};
  border-radius: 60px;
  color: ${({ $isSell }) => ($isSell ? '#FF8399' : '#D4D9FF')};
  & + & {
    margin-left: 10px;
  }

  &:hover {
    border-color: ${({ $isSell }) => ($isSell ? '#FF8399' : '#D4D9FF')};
  }
`

export const TokenItemWrapper = styled(Box)<{ $noDivider: boolean }>`
  flex-direction: row;
  align-items: center;
  height: 60px;
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}
`

export const TokenItemMobileWrapper = styled(Box)<{ $noDivider: boolean }>`
  padding: 18px 0 16px;
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}
`

export const MobileDivider = styled(Box)`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.veryDarkGrayishBlue};
  margin: 0 32px;

  ${MediaQuery.phone`
  margin: 0 16px;
  `}
`

export const MobileAssetWrapper = styled(Box)`
  flex: 1;
  margin-left: 12px;
  p:first-child {
    ${MediaQuery.tablet`
        font-size: 18px;
    `}
  }
  p:last-child {
    ${MediaQuery.tablet`
        font-size: 16px;
    `}
  }
`

export const CoinIcon = styled(LogoIcon)`
  width: 29px;
  border-radius: 50%;

  ${MediaQuery.tablet`
    width: 35px;
  `}
  ${MediaQuery.phone`
    width: 32px;
  `}
`

export const PriceLabel = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};
  ${MediaQuery.phone`
    font-size: 12px;
  `}
`

export const PriceValueLabel = styled(Typography)`
  display: flex;
  &,
  p {
    font-size: 16px;
  }
  ${MediaQuery.phone`
    &, p {
      font-size: 14px;
    }
  `}
`

import History from 'pages/Portfolio/History'
import styled from 'styled-components/macro'

import PortfolioTokens from '../Portfolio/Tokens'
import StakeList from '../StakeListPage/StakeList'
import TradingList from './DelegatedList'
import { Container, PanelItemBox, PanelLayout } from './styles'

const TradingListStyled = styled(TradingList)`
  margin-top: 15px;
`
/**
 * Dashboard Page
 * @constructor
 */
const DashboardPage = () => {
  return (
    <Container>
      <PanelLayout>
        <PanelItemBox flex={2}>
          <PortfolioTokens limited />
        </PanelItemBox>
        <PanelItemBox flex={1} ml={15}>
          <History />
          <TradingListStyled />
        </PanelItemBox>
      </PanelLayout>

      <PanelLayout>
        <PanelItemBox flex={1}>
          <StakeList limited />
        </PanelItemBox>
      </PanelLayout>
    </Container>
  )
}

export default DashboardPage

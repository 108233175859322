import { Grid, Stack } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useClaimedRewardByAddress } from 'api'
import { TabButton } from 'components'
import { useTrans } from 'i18n/useTransHook'
import { useIsMobile, useIsTablet } from 'nft/hooks'
import { useState } from 'react'

import { Accruals } from './Accruals'
import { Codes } from './Codes'
import { ReferralsCodeGenerator } from './ReferralCodesGenerator'
import { Container, Description, TextContainer, Title } from './styles'

/**
 * referrals Page
 * @constructor
 */
const ReferralsPage = () => {
  const { t } = useTrans('Referrals')

  const { account } = useWeb3React()

  const [activeTab, setActiveTab] = useState<'accruals' | 'codes'>('accruals')

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const isMobileOrTablet = isMobile || isTablet

  const { list: refCodes } = useClaimedRewardByAddress({
    address: account,
  })

  return (
    <Container>
      {/* TODO: Remove after testing */}

      <TextContainer>
        <Title>{t('Referrals')}</Title>
        <Description>{t('Description_text')}</Description>
      </TextContainer>
      {refCodes?.length > 0 && account ? (
        <>
          {isMobileOrTablet ? (
            <>
              <Stack direction="row" paddingLeft={16} mt={32}>
                <TabButton onClick={() => setActiveTab('accruals')} $selected={activeTab === 'accruals'}>
                  {t('Rebates')}
                </TabButton>
                <TabButton onClick={() => setActiveTab('codes')} $selected={activeTab === 'codes'}>
                  {t('Codes')}
                </TabButton>
              </Stack>
              {activeTab === 'accruals' ? <Accruals /> : <Codes data={refCodes} />}
            </>
          ) : (
            <Grid columns={2} columnGap={20} container marginTop={64} width="100%" alignItems="flex-start">
              <Accruals />
              <Grid item flex={1}>
                <Stack gap={20}>
                  <Codes data={refCodes} />
                </Stack>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <ReferralsCodeGenerator />
      )}
    </Container>
  )
}

export default ReferralsPage

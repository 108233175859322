import { LoadingBubble } from './Tokens/loading'

const ValueLoading = ({ children, loading }: { children: any; loading?: boolean }) => {
  if (!loading) {
    return children
  } else {
    return <LoadingBubble height="18px" width="60px" />
  }
}

export default ValueLoading

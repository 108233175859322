import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './en/translation.json'
import ru from './ru/translation.json'

export const defaultNS = 'Common'

const resources = {
  en,
  ru,
}

i18next.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
  defaultNS,
})

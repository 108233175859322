import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReferralsCodeGenerator } from './ReferralCodesGenerator'
import { DialogContainer, GradientOutlineButton } from './styles'

export const CreateCodeModal = () => {
  const [open, toggleModal] = useState(false)

  const { t } = useTranslation('Referrals')

  return (
    <>
      <GradientOutlineButton
        onClick={() => {
          toggleModal(true)
        }}
      >
        {t('Create')}
      </GradientOutlineButton>
      <DialogContainer
        onClose={() => {
          toggleModal(false)
        }}
        open={open}
      >
        <ReferralsCodeGenerator
          onClose={() => {
            toggleModal(false)
          }}
          variant="modal"
        />
      </DialogContainer>
    </>
  )
}

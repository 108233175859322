import { TextField, Typography } from '@mui/material'
import { Box } from 'components'
import { useTrans } from 'i18n/useTransHook'

import { BuyButton, CancelButton, DialogContainer, MailRow, SendButton } from './styles'
import useContactMeDlg, { IContactMePayment } from './useContactMeDlg'

/**
 * Contact Me Dialog
 * @constructor
 */
const ContactMeDialog = () => {
  const { open, toggleModal, payment, setPayment } = useContactMeDlg()
  const { t } = useTrans('ContactDialog')

  return (
    <DialogContainer onClose={toggleModal} open={open}>
      <Box>
        <Typography fontSize={24} mb={25}>
          {t('Contact me')}
        </Typography>

        <TextField label={t('First name')} variant="outlined" />
        <TextField label={t('Last name')} variant="outlined" sx={{ mt: 20 }} />

        <MailRow>
          <TextField label="E-mail" variant="outlined" sx={{ flex: 1 }} />
          <TextField label={t('Phone number')} variant="outlined" sx={{ flex: 1, ml: 13 }} />
        </MailRow>

        <Typography color="lightGrayishBlue" fontSize={15} mt={20}>
          {t('Topic related')}
        </Typography>
        <Box flexDirection="row" alignItems="center" mt={4}>
          <BuyButton $selected={payment === IContactMePayment.BUY} onClick={() => setPayment(IContactMePayment.BUY)}>
            {t('Buy')}
          </BuyButton>
          <BuyButton $selected={payment === IContactMePayment.SELL} onClick={() => setPayment(IContactMePayment.SELL)}>
            {t('Sell')}
          </BuyButton>
        </Box>

        <Box flexDirection="row" alignItems="center" mt={50} justifyContent="flex-end">
          <CancelButton onClick={toggleModal}>{t('Cancel')}</CancelButton>
          <SendButton disabled>{t('Send')}</SendButton>
        </Box>
      </Box>
    </DialogContainer>
  )
}

export default ContactMeDialog

import { Typography } from '@mui/material'
import { Box } from 'components'
import { useTrans } from 'i18n/useTransHook'

import { DialogContainer, DialogContent, SearchInput, TokenItemContainer, TokenList } from './styles'
import useSelectTokenDlgHook, { ISelectTokenDialog, IToken, ITokenItem } from './useSelectTokenDlgHook'

const TokenItem = ({ token, onClick, lastItem }: ITokenItem) => (
  <TokenItemContainer onClick={() => onClick(token)} $noDivider={lastItem}>
    <Box flexDirection="row" alignItems="center">
      <img src={token.icon} width={30} />
      <Box ml={13}>
        <Typography fontSize={15} textAlign="left">
          {token.symbol}
        </Typography>
        <Typography fontSize={14} color="lightGrayishBlue">
          {token.name}
        </Typography>
      </Box>
    </Box>
  </TokenItemContainer>
)

const SelectTokenDialog = (props: ISelectTokenDialog) => {
  const { keyword, handleChangeKeyword, tokens } = useSelectTokenDlgHook(props)
  const { onChangeToken, open, onClose } = props
  const { t } = useTrans('SelectTokenDialog')

  const handleChangeToken = (value: IToken) => {
    onChangeToken?.(value)
    onClose()
  }

  return (
    <DialogContainer onClose={onClose} open={open}>
      <DialogContent>
        <Typography fontSize={24}>{t('Select token')}</Typography>
        <SearchInput value={keyword} onChange={handleChangeKeyword} placeholder={`${t('Search')}...`} />
        {tokens.length === 0 && (
          <Typography color="lightGrayishBlue" mt={25}>
            No available tokens
          </Typography>
        )}
        <TokenList>
          {tokens.map((token, index) => (
            <TokenItem key={index} token={token} onClick={handleChangeToken} lastItem={tokens.length - 1 === index} />
          ))}
        </TokenList>
      </DialogContent>
    </DialogContainer>
  )
}

export default SelectTokenDialog

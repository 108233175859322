import { Stack } from '@mui/material'
import { IRefCodeRegistered } from 'api'
import { useTranslation } from 'react-i18next'

import { CreateCodeModal } from '../CreateCodeModal'
import { Card, Subtitle } from '../styles'
import { CodesList } from './CodesList'

export const Codes = ({ data }: { data: IRefCodeRegistered[] }) => {
  const { t } = useTranslation('Referrals')

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Subtitle>{t('ReferralCodes')}</Subtitle>
        <CreateCodeModal />
      </Stack>
      <CodesList data={data} />
    </Card>
  )
}

import { Typography } from '@mui/material'
import styled from 'styled-components/macro'
import { GradientColors } from 'utils/colors'

import { Button } from '../index'

export const GradientButtonContained = styled(Button)`
  background: ${GradientColors.gradient1};
  height: 40px;
  padding: 0 27px;
  border-radius: 12px;
  font-size: 15px;
`

export const GradientButtonOutlined = styled(Button)<{ $bgColor?: string }>`
  &,
  &:hover {
    background: linear-gradient(${({ $bgColor }) => `${$bgColor}, ${$bgColor}`}) padding-box,
      linear-gradient(128.01deg, #fe4cff -18.42%, #5badff 80.5%, #cce6ff 187.16%) border-box;
    border: 1px solid transparent;
  }
`

export const GradientText = styled(Typography)`
  background: linear-gradient(128.01deg, #fe4cff -18.42%, #5badff 80.5%, #cce6ff 187.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
`

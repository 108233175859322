import { Currency, CurrencyAmount, Price } from '@uniswap/sdk-core'
import { nativeOnChain } from 'constants/tokens'
import { isGqlSupportedChain } from 'graphql/data/util'

import useStablecoinPrice from './useStablecoinPrice'

function useETHValue(currencyAmount?: CurrencyAmount<Currency>): {
  data: CurrencyAmount<Currency> | undefined
  isLoading: boolean
} {
  const chainId = currencyAmount?.currency?.chainId

  // Get ETH value of ETH or WETH
  if (chainId && currencyAmount && currencyAmount.currency.wrapped.equals(nativeOnChain(chainId).wrapped)) {
    return {
      data: new Price(currencyAmount.currency, currencyAmount.currency, '1', '1').quote(currencyAmount),
      isLoading: false,
    }
  }

  return { data: undefined, isLoading: false }
}

export function useUSDPrice(currencyAmount?: CurrencyAmount<Currency>): {
  data: number | undefined
  isLoading: boolean
} {
  const currency = currencyAmount?.currency
  const { data: ethValue, isLoading: isEthValueLoading } = useETHValue(currencyAmount)

  // Use USDC price for chains not supported by backend yet
  const stablecoinPrice = useStablecoinPrice(!isGqlSupportedChain(currency?.chainId) ? currency : undefined)
  if (!isGqlSupportedChain(currency?.chainId) && currencyAmount && stablecoinPrice) {
    return { data: parseFloat(stablecoinPrice.quote(currencyAmount).toSignificant()), isLoading: false }
  }

  // Otherwise, get the price of the token in ETH, and then multiple by the price of ETH
  if (!ethValue) return { data: undefined, isLoading: isEthValueLoading }

  return { data: parseFloat(ethValue.toExact()), isLoading: false }
}

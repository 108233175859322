import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import IconTemplate from '../../assets/svg/placeholder.webp'

const ImgParticipants = styled.img``

interface UseAssetIconProps {
  icons?: (string | undefined)[]
}

interface UseAssetIconRes {
  icon: string | undefined
  handleImageError: () => void
}

const useAssetIcon = ({ icons }: UseAssetIconProps): UseAssetIconRes => {
  const getNextAvailableIndex = useCallback(
    (index: number) => {
      if (!icons) {
        return 0
      }
      let result = index + 1
      while (result < icons.length && !icons[result]) {
        result = result + 1
      }
      return result
    },
    [icons],
  )

  const [iconIndex, setIconIndex] = useState(() => getNextAvailableIndex(-1))

  useEffect(() => {
    setIconIndex(0)
  }, [icons])

  const icon = useMemo(() => {
    return icons && icons[iconIndex]
  }, [iconIndex, icons])

  const handleImageError = useCallback(() => {
    setIconIndex(getNextAvailableIndex(iconIndex))
  }, [getNextAvailableIndex, iconIndex])

  return {
    icon,
    handleImageError,
  }
}

const LogoIcon = ({
  src,
  el: El = ImgParticipants,
  asBg = false,
  handleError,
  ...rest
}: {
  src?: string | string[]
  el?: any
  asBg?: boolean
  handleError?: (v: boolean) => void
}) => {
  const props = useMemo(() => {
    return { icons: Array.isArray(src) ? src : [src] }
  }, [src])

  const { icon, handleImageError } = useAssetIcon(props)

  const handleErrorCallback = useCallback(() => {
    handleImageError()
    handleError && handleError(true)
  }, [handleError, handleImageError])

  if (asBg) {
    const bgImgStyles = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
    }
    if (icon) {
      return (
        <El
          {...rest}
          style={{
            background: `url(${icon})`,
            ...bgImgStyles,
          }}
          onError={handleErrorCallback}
        />
      )
    } else {
      return (
        <El
          {...rest}
          style={{
            background: `url(${IconTemplate})`,
            ...bgImgStyles,
          }}
          onError={handleErrorCallback}
        />
      )
    }
  }

  if (icon) {
    return <El {...rest} src={icon} onError={handleErrorCallback} />
  } else {
    return <El {...rest} src={IconTemplate} onError={handleErrorCallback} />
  }
}

export default LogoIcon

import { Dialog, Grid, TextField, Typography } from '@mui/material'
import { Box, Button, GradientButton, PanelBox } from 'components'
import { InfiniteScrollList } from 'components/InfiniteScroll/InfiniteScrollList'
import LogoIcon from 'components/LogoIcon/LogoIcon'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex: 1;
  margin: 40px 0;

  ${MediaQuery.tablet`
    margin: 32px 0;
  `};
`

export const Card = styled(PanelBox)`
  padding: 32px 40px;

  ${MediaQuery.tablet`
    padding: 20px 24px;
    border-radius: 22px;
  `};
`

export const CreateReferralCodeCard = styled(Card)`
  margin-top: 64px;

  ${MediaQuery.tablet`
    padding: 24px 16px;
    margin-top: 32px;
    border-radius: 20px;
  `};
`

export const TextContainer = styled.div`
  max-width: 570px;
`

export const CodeInput = styled(TextField)`
  flex: 1;
  margin-top: 6px;
  .MuiOutlinedInput-root {
    border-radius: 12px;

    :hover fieldset,
    &.Mui-focused fieldset {
      border-color: #5d6290;
      border-width: 1px;
    }

    .MuiInputBase-input {
      padding: 12px 20px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    font-size: 18px;
    line-height: 24px;

    ${MediaQuery.tablet`
      font-size: 16px;
    `}
  }
`

export const GenerateBtn = styled(GradientButton)`
  width: fit-content;
  height: auto;
  padding: 12px 32px;
  border-radius: 60px;
  font-size: 17px;
  line-height: 25px;

  ${MediaQuery.phone`
    width: 100%;
  `}
`

export const GradientOutlineButton = styled(GradientButton).attrs((props) => ({
  variant: 'outlined',
  bgColor: props.theme.palette.veryDarkDesaturatedBlue,
}))`
  height: auto;
  padding: 8px 24px;
  border-radius: 60px;

  > * {
    font-size: 16px;
    line-height: 24px;
  }

  & + & {
    margin-left: 13px;

    ${MediaQuery.tablet`
      margin: 13px 0 0;
    `}
  }
`

export const Row = styled(Grid).attrs({
  container: true,
  gap: 30,
})`
  border-bottom: 1px solid #484768;

  ${MediaQuery.tablet`
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  `}
`

export const Cell = styled(Grid).attrs({
  item: true,
  flex: 1,
})``

export const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  overflow: visible !important;
  margin-top: 4px;
`

export const CoinIcon = styled(LogoIcon)`
  width: 32px;
  border-radius: 50%;
`

export const WithdrawButton = styled(Button).attrs({
  variant: 'outlined',
})`
  padding: 8px 24px;
  height: auto;
  border-radius: 60px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};
  font-size: 16px;

  &,
  &:hover {
    border-color: ${({ theme }) => theme.palette.lightGrayishBlue};
  }

  ${MediaQuery.tablet`
    width: 100%;
    border-color: ${({ theme }) => theme.palette.verySoftBlue};
    color: ${({ theme }) => theme.palette.verySoftBlue};
    margin-top: 20px;
  `}
`

export const IconButton = styled.button`
  width: 22px;
  height: 22px;
  padding: 0;
  background-color: transparent;
  border: none;
  transition: opacity 0.025s;
  user-select: none;

  :focus {
    outline: none;
    background-color: transparent;
  }

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.5;
  }

  ${MediaQuery.tablet`
    width: 24px;
    height: 24px;
  `}
`

export const DialogContainer = styled(Dialog)`
  .MuiPaper-root {
    padding: 30px 40px;
    max-width: 600px;
    width: 100%;

    ${MediaQuery.phone`
      margin: 16px;
      padding: 24px;
    `}
  }
`

export const CancelCreateButton = styled(Button).attrs({
  variant: 'outlined',
})`
  padding: 12px 32px;
  height: auto;
  border-radius: 60px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};
  font-size: 16px;

  &,
  &:hover {
    border-color: ${({ theme }) => theme.palette.lightGrayishBlue};
  }

  ${MediaQuery.screen`
    width: 24px;
    border: 0;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 24px;
    right: 24px;
  `}
`

export const Subtitle = styled(Typography)`
  font-size: 28px;

  ${MediaQuery.tablet`
    font-size: 21px;
  `}
`
export const Title = styled(Typography)`
  font-size: 36px;

  ${MediaQuery.tablet`
    font-size: 30px;
  `}
`

export const Description = styled(Typography)`
  font-size: 18px;
  margin-top: 13px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};

  ${MediaQuery.tablet`
    margin-top: 8px;
  `}
`

import { SupportedChainId } from '@uniswap/sdk-core'
import { Referrals } from 'abis/types'
import { NETWORK_SELECTOR_CHAINS } from 'components/NavBar/ChainSelector'
import { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useReferralsContract } from 'utils/blackbunny'

import { useLocalStorage } from './useLocalStorage'
import { useActiveWeb3React } from './web3'

const urlParams = new URLSearchParams(window.location.search)

interface IRefCodeRecord {
  [chainId: number]: string
}

export const useSavedRefCode = () => {
  const [refCode, setRefCode] = useLocalStorage<IRefCodeRecord>('refCode-v4', {} as IRefCodeRecord)
  const [isCodeAvailable, setIsCodeAvailable] = useState(false)
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const refContract = useReferralsContract() as Referrals

  useEffect(() => {
    async function checkCode() {
      const code = refCode[chainId]

      if (!code) {
        setIsCodeAvailable(false)
        return
      }

      const codeHash = await refContract.getReferralCodeHash(code)

      refContract.isReferralCodeExist(codeHash).then((result) => {
        setIsCodeAvailable(result)
      })
    }

    try {
      checkCode()
    } catch (e) {
      setIsCodeAvailable(false)
      console.error('Failed to check referral code', e)
    }
  }, [chainId, refCode, refContract])

  return useMemo(() => {
    return {
      refCode: {
        network: chainId,
        code: refCode[chainId] || '',
      },
      isCodeAvailable,
      setRefCode,
    }
  }, [refCode, isCodeAvailable, setRefCode, chainId])
}

export const useReferralCodeFromParams = () => {
  const { setRefCode } = useSavedRefCode()
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const code = useMemo(() => {
    return urlParams.get('refCode')
  }, [])

  const codeModel = useMemo(() => {
    const network =
      isNaN(Number(urlParams.get('network'))) && chainId
        ? chainId
        : (Number(urlParams.get('network')) as SupportedChainId)
    return { network: network || undefined, code: code || '' }
  }, [code, chainId])

  useEffect(() => {
    console.log('Loading Cookies-1', chainId)
    if (codeModel.code) {
      console.log('Loading Cookies-1.1', codeModel)
      setRefCode((prev) => {
        if (codeModel.network && prev[codeModel.network] === codeModel.code) return prev

        return {
          ...prev,
          [codeModel.network || chainId]: codeModel.code,
        }
      })
    }
  }, [codeModel, chainId, setRefCode])

  const [cookiesRefCode] = useCookies()

  useEffect(() => {
    for (let i = 0; i < NETWORK_SELECTOR_CHAINS.length; i++) {
      const chain = NETWORK_SELECTOR_CHAINS[i]

      const cookieRefCode = cookiesRefCode['network-' + chain]

      console.log('Loading Cookies-2', cookiesRefCode, cookieRefCode, chain)

      if (cookieRefCode && chain) {
        console.log('Loading Cookies-2.1', cookieRefCode)

        setRefCode((prev) => {
          if (chain && prev[chain] === cookieRefCode) return prev
          return {
            ...prev,
            [chain]: cookieRefCode,
          }
        })
      }
    }
  }, [codeModel, cookiesRefCode, setRefCode])

  return codeModel
}

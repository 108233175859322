import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { Trans } from '@lingui/macro'
import { Typography } from '@mui/material'
import usdtIcon from 'assets/icons/coins/usdt.png'
import { AmountInputWithMax } from 'blocks/AmountInputRefactored/AmountInput'
import { CheckLoginBtn } from 'components/Web3Status'
import { ZERO_HASH } from 'constants/misc'
import { useSavedRefCode, useTokenBalance } from 'hooks'
import { useTxTemplate } from 'hooks/tx-template'
import { ApproveCheckerDelegationProxy } from 'hooks/useApproveCallback'
import { useTrans } from 'i18n/useTransHook'
import { Dots } from 'pages/Pool/styleds'
import { useCallback, useMemo, useState } from 'react'
import { TransactionType } from 'state/transactions/types'
import { useDelegationContract, useReferralsContract, useUsdtContract } from 'utils/blackbunny'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import {
  ConfirmButton,
  Container,
  InfoPanel,
  LeftLayout,
  LeverageSlider,
  PanelLayout,
  SliderWrapper,
  TransferPanel,
} from './styles'
import useDelegateView from './useDelegateView'

const APY_PERCENTS: { [key: number]: string } = {
  3: '3',
  4: '3',
  5: '3',
  6: '4',
  7: '4',
  8: '4',
  9: '5',
  10: '5',
  11: '5',
  12: '6',
}

const useTransfer = (tokenContract: Contract | null, amount: BigNumber = ZERO, period: number, decimals: number) => {
  const contract = useDelegationContract()

  const { refCode, isCodeAvailable } = useSavedRefCode()
  const refContract = useReferralsContract()

  const dataFunc = useCallback(async () => {
    let refCodeHash = ZERO_HASH
    if (refCode.code && isCodeAvailable) {
      refCodeHash = (await refContract?.getReferralCodeHash(refCode.code)) as string
    }

    const tx = await contract?.populateTransaction.deposit(tokenContract?.address || '', amount, period, refCodeHash)
    return {
      ...tx,
    }
  }, [contract, tokenContract, amount, period, refCode, refContract, isCodeAvailable])

  const tx = useTxTemplate(
    TransactionType.DELEGATE_TO_TRADE,
    `Delegated ${formatDecimal(amount, 2, decimals)} USDT ${period} months`,
    dataFunc,
    `delegation_${amount}`,
  )
  return tx
}

const tokenInfo = {
  symbol: 'USDT',
  name: 'Tether USDT',
  icon: usdtIcon,
}

/**
 * Delegate View
 * @constructor
 */
const DelegateView = () => {
  const usdt = useUsdtContract()
  const { percentValue, handleChangePercent } = useDelegateView()
  const { t } = useTrans('Delegate')
  const [value, setValue] = useState<BigNumber | undefined>()

  const decimals = 6

  const { result: balance } = useTokenBalance(usdt?.address || '')

  const { action, pending } = useTransfer(usdt, value, percentValue, decimals)

  const minValue = useMemo(() => {
    return BigNumber.from(10).pow(decimals).mul(1000)
  }, [decimals])

  return (
    <Container>
      <PanelLayout>
        <LeftLayout>
          <TransferPanel flex={1}>
            <AmountInputWithMax
              label={t('You transfer')}
              inputValue={value}
              rightToken={tokenInfo}
              showBalanceRow={false}
              decimals={decimals}
              setInputValue={(v) => v && setValue(v)}
            />

            <Typography fontSize={12} fontWeight={400} color="lightGrayishBlue" display="flex" gap="12px">
              {t('MinDelegationWarning')}

              {balance?.balance && value && balance.balance.lt(value) && (
                <Typography color="red" fontSize={12} ml="auto">
                  {t('Insufficient funds')}
                </Typography>
              )}
            </Typography>

            <SliderWrapper>
              <Typography fontSize={18} fontWeight={500} mb={25}>
                {t('Investment period')}
              </Typography>
              <LeverageSlider
                min={3}
                max={12}
                step={3}
                value={percentValue}
                onChange={handleChangePercent}
                valueLabelDisplay="auto"
              />
              <Typography fontSize={15} textAlign="right" mt={5}>
                {percentValue} {t('months of locked funds')} / {APY_PERCENTS[percentValue]}% {t('Per_month')}
              </Typography>
            </SliderWrapper>

            <CheckLoginBtn>
              <ApproveCheckerDelegationProxy spendingToken={usdt?.address || ''} amount={value || ZERO}>
                {pending ? (
                  <Dots>
                    <Trans>Loading</Trans>
                  </Dots>
                ) : (
                  <ConfirmButton disabled={!value || value.lt(minValue)} onClick={action}>
                    {t('Confirm')}
                  </ConfirmButton>
                )}
              </ApproveCheckerDelegationProxy>
            </CheckLoginBtn>
          </TransferPanel>

          <InfoPanel mt={15}>
            <Typography fontSize={23}>{t('Delegate funds to a trader')}</Typography>
            <Typography mt={12} fontSize={14}>
              {t('Description_text')}
            </Typography>
          </InfoPanel>
        </LeftLayout>
      </PanelLayout>
    </Container>
  )
}

export default DelegateView

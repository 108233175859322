import { ISelectTokenDialog, IToken } from 'blocks/SelectTokenDialog/useSelectTokenDlgHook'
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import SelectTokenDialog from './index'

interface ISelectTokenDialogProvider {
  children: ReactNode
}

type ISelectTokenDialogConfig = Pick<ISelectTokenDialog, 'type' | 'onChangeToken'>

interface ISelectTokenDialogContext {
  openDialog: (props: ISelectTokenDialogConfig) => void
}

export const SelectTokenDialogContext = createContext<ISelectTokenDialogContext>({ openDialog: () => '' })

const SelectTokenDialogProvider = ({ children }: ISelectTokenDialogProvider) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogConfig, setDialogConfig] = useState<ISelectTokenDialogConfig>()

  const openDialog = useCallback(
    ({ type, onChangeToken }: ISelectTokenDialogConfig) => {
      setDialogConfig({ type, onChangeToken })
      setDialogOpen(true)
    },
    [setDialogConfig, setDialogOpen],
  )

  const providerValue = useMemo(() => ({ openDialog }), [openDialog])

  const onClose = useCallback(() => {
    setDialogOpen(false)
    setDialogConfig(undefined)
  }, [setDialogConfig, setDialogOpen])

  const handleChangeToken = useCallback(
    (value: IToken) => {
      dialogConfig?.onChangeToken?.(value)
    },
    [dialogConfig],
  )

  return (
    <SelectTokenDialogContext.Provider value={providerValue}>
      {children}
      <SelectTokenDialog
        open={dialogOpen}
        onClose={onClose}
        onChangeToken={handleChangeToken}
        type={dialogConfig?.type}
      />
    </SelectTokenDialogContext.Provider>
  )
}

export default SelectTokenDialogProvider

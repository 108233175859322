import { Typography } from '@mui/material'
import { CoinButton } from 'blocks/AmountInput/styles'

import { IPickerToken } from './useAmountInput'

interface IRightTokenSelector {
  value: IPickerToken
}

export const RightTokenSelector = ({ value }: IRightTokenSelector) => {
  return (
    <CoinButton disabled>
      <img src={value.icon} width={18} />
      <Typography mx={6}>{value.symbol}</Typography>
    </CoinButton>
  )
}

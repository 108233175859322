import { Typography } from '@mui/material'
import styled from 'styled-components/macro'
import { GradientColors } from 'utils/colors'

const GradientTypography = styled(Typography)`
  background: ${GradientColors.gradient1};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default GradientTypography

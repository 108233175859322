import { useState } from 'react'
import { useModalIsOpen, useToggleModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

export enum IContactMePayment {
  BUY = 'BUY',
  SELL = 'SELL',
}

/**
 * Contact Me Dialog Logic
 */
const useContactMeDlg = () => {
  const open = useModalIsOpen(ApplicationModal.CONTACT_ME)
  const toggleModal = useToggleModal(ApplicationModal.CONTACT_ME)
  const [payment, setPayment] = useState<IContactMePayment>()

  return {
    open,
    toggleModal,
    payment,
    setPayment,
  }
}

export default useContactMeDlg

import { Box, PanelBox } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(PanelBox)`
  padding: 25px 30px;

  ${MediaQuery.screen`
    padding: 20px 10px;
  `};
  ${MediaQuery.tablet`
    padding: 25px 16px;
  `};
`

export const Banner = styled(Box)`
  align-self: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.mostlyDesaturatedDarkBlue};
  border-radius: 7px;
  height: 24px;
  padding: 0 8px;
  margin-top: 3px;
`

export const TokenItemContainer = styled(Box)<{ $noDivider: boolean }>`
  height: 65px;
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}

  &:first-child {
    margin-top: 20px;
  }

  ${MediaQuery.tablet`
  height: unset;
  padding: 16px 0;
  margin-top: 4px;
  `}

  ${({ $noDivider }) => $noDivider && MediaQuery.tablet`padding-bottom: 10px;`}

  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.palette.lightGrayishBlue};
  }
`

export const TokenItemWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  flex: 1;

  ${MediaQuery.tablet`
    flex: none;
  `}
`

export const FromLabelWrapper = styled(Box)`
  flex: 1;
  ${MediaQuery.tablet`
    display: none;
  `}
`

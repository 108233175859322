import { useState } from 'react'

export enum DelegateTabs {
  DELEGATE = 'DELEGATE',
  WITHDRAW = 'WITHDRAW',
}

/**
 * Delegate Page Logic
 */
const useDelegate = () => {
  const [activeTab, setActiveTab] = useState<DelegateTabs>(DelegateTabs.DELEGATE)

  return {
    activeTab,
    setActiveTab,
  }
}

export default useDelegate

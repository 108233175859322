import { useCallback, useEffect, useMemo, useState } from 'react'
import { Loader } from 'react-feather'
import InfiniteScroll from 'react-infinite-scroll-component'

const PER_PAGE = 10

interface IScrollProps {
  itemsTotal: any[]
  renderItem: (item: any, index: number) => any
  className?: string
  perPage?: number
}

const useInfiniteScrollProps = (itemsTotal: any[], perPage: number) => {
  const [currentList, setCurrentList] = useState<any[]>(() => (itemsTotal ? itemsTotal.slice(0, perPage) : []))

  useEffect(() => {
    setCurrentList(() => (itemsTotal ? itemsTotal.slice(0, perPage) : []))
  }, [itemsTotal, perPage])

  const fetchData = useCallback(() => {
    if (currentList.length === itemsTotal.length) {
      return
    }

    const newOffset = Math.min(currentList.length + perPage, itemsTotal.length)
    const nextList = itemsTotal.slice(0, newOffset)
    setCurrentList(nextList)
  }, [currentList, itemsTotal, perPage])

  const hasMore = currentList.length < itemsTotal.length

  return useMemo(() => ({ currentList, hasMore, fetchData }), [currentList, hasMore, fetchData])
}

export const InfiniteScrollList = ({ className, itemsTotal, renderItem, perPage = PER_PAGE }: IScrollProps) => {
  const { currentList, hasMore, fetchData } = useInfiniteScrollProps(itemsTotal, perPage)

  return (
    <InfiniteScroll
      className={className}
      dataLength={currentList.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<Loader />}
      scrollableTarget="body_over"
    >
      {currentList.map((item: any, index: number) => renderItem(item, index))}
    </InfiniteScroll>
  )
}

import styled from 'styled-components/macro'

import Box from '../index'

const TypeBox = styled(Box)`
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background-color: ${({ theme }) => theme.palette.veryDarkDesaturatedBlue2};
  border-radius: 9px;
`

export default TypeBox

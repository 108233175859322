import { Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { Box } from 'components'
import StatusIcon from 'components/Identicon/StatusIcon'
import { useGetConnection } from 'connection'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import { useTrans } from 'i18n/useTransHook'
import { shortenAddress } from 'nft/utils/address'
import { useMagicWallet } from 'pages/MagicWallet'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  BUY_SELL_URL,
  DASHBOARD_URL,
  DELEGATE_URL,
  PORTFOLIO_URL,
  REFERRALS_URL,
  STAKE_LIST_URL,
  SWAP_URL,
} from 'routes/paths'
import styled from 'styled-components/macro'
import { CopyHelper } from 'theme'
import { MEDIA_WIDTHS } from 'utils/mediaQuery'

import dashboardIcon from '../assets/dashboard.svg'
import delegateIcon from '../assets/delegate.svg'
import portfolioIcon from '../assets/portfolio.svg'
import referralsIcon from '../assets/referrals.svg'
import stakeIcon from '../assets/stake.svg'
import swapIcon from '../assets/swap.svg'
import { Container, NavButtonBase, NavIcon, ProfileBox } from './styles'

interface INavButton {
  icon: string
  text: string
  route?: string
  onClick?: () => void
  onClose?: () => void
  disabled?: boolean
}

const NavButton = ({ icon, text, route, onClick, onClose, disabled }: INavButton) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isSelected = route ? location.pathname.startsWith(route) : false
  const { t } = useTrans('Layout')

  const handleClick = () => {
    if (onClose) {
      onClose()
    } else if (onClick) {
      onClick()
    }
    route && navigate(route)
  }

  return (
    <NavButtonBase variant="text" $highlighted={isSelected} onClick={handleClick} disabled={disabled}>
      <NavIcon src={icon} />
      <Typography flex={1} textAlign="left">
        {t(text)}
      </Typography>
    </NavButtonBase>
  )
}

interface ISideMenuContent {
  onClose?: () => void
}

const CopyText = styled(CopyHelper).attrs({
  gap: 4,
  iconSize: 14,
  iconPosition: 'right',
})``

export const SideMenuContent = ({ onClose }: ISideMenuContent) => {
  const { connector, ENSName, account } = useWeb3React()
  const getConnection = useGetConnection()
  const connection = getConnection(connector)

  return (
    <Box position="sticky" top={0}>
      <ProfileBox>
        <StatusIcon connection={connection} size={40} />
        <Box ml={17}>
          {account && (
            <CopyText toCopy={ENSName ?? account}>
              <Typography fontWeight={500}>{ENSName ?? shortenAddress(account, 4, 4)}</Typography>
            </CopyText>
          )}
          {/* <Box flexDirection="row" alignItems="center" mt={3}> */}
          {/*  <img src={starIcon} /> */}
          {/*  <Typography ml={5} fontSize={16} color="lightGrayishBlue"> */}
          {/*    PRO */}
          {/*  </Typography> */}
          {/* </Box> */}
        </Box>
      </ProfileBox>
      <NavButton icon={dashboardIcon} text="Dashboard" route={DASHBOARD_URL} onClose={onClose} />

      <NavButton icon={swapIcon} text="Swap" route={SWAP_URL} onClose={onClose} />
      <NavButton icon={stakeIcon} text="Stake" route={STAKE_LIST_URL} onClose={onClose} />
      <NavButton icon={dashboardIcon} text="Buy Crypto" route={BUY_SELL_URL} onClose={onClose} />
      <NavButton icon={portfolioIcon} text="Portfolio" route={PORTFOLIO_URL} onClose={onClose} />
      <NavButton icon={delegateIcon} text="Delegate" route={DELEGATE_URL} onClose={onClose} />
      <NavButton icon={referralsIcon} text="Referrals" route={REFERRALS_URL} onClose={onClose} />
      {connection && connection.getName() === 'Magic Link' && <MagicWalletBtn />}
    </Box>
  )
}

const MagicWalletBtn = () => {
  const open = useMagicWallet()
  return <NavButton icon={portfolioIcon} text="Magic Wallet" onClick={open} />
}

/**
 * Side Menu
 * @constructor
 */
const SideMenu = () => {
  const { windowWidth } = useWindowDimensions()

  if (windowWidth > MEDIA_WIDTHS.large) {
    return (
      <Container>
        <SideMenuContent />
      </Container>
    )
  }
  return null
}

export default SideMenu

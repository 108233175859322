import { Drawer, Typography } from '@mui/material'
import { formatNumber, NumberType } from '@uniswap/conedison/format'
import { useWeb3React } from '@web3-react/core'
import hamburgerIcon from 'assets/icons/hamburger.svg'
import { Box } from 'components'
import { portfolioFadeInAnimation } from 'components/AccountDrawer/MiniPortfolio/PortfolioRow'
import { LanguageMenuItem } from 'components/AccountDrawer/SettingsMenu'
import Column from 'components/Column'
import { ChainSelector } from 'components/NavBar/ChainSelector'
import { ChainSelector as ChainSelectorCss } from 'components/NavBar/ChainSelector.css'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import { LoadingBubble } from 'components/Tokens/loading'
import Web3Status from 'components/Web3Status'
import { useWarningFlag, WrongNetworkBanner } from 'components/WrongNetworkBanner/WrongNetworkBanner'
import { SHORT_LOCALE_LABEL, SUPPORTED_LOCALES } from 'constants/locales'
import { useAccountTokens } from 'hooks/useAccountTokens'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import { Row } from 'nft/components/Flex'
import { useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import { useTheme } from 'styled-components/macro'
import styled from 'styled-components/macro'
import { CopyHelper, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { MEDIA_WIDTHS } from 'utils/mediaQuery'

import { SideMenuContent } from '../SideMenu'
import logoIcon from './assets/logo.svg'
import { Container, CTAWrapper, HamburgerButton, MobileHeaderContainer, ProfileButton } from './styles'
import useMobileHeader from './useMobileHeader'

const CopyText = styled(CopyHelper).attrs({
  gap: 4,
  iconSize: 16,
  iconPosition: 'right',
  showForce: true,
})``

const ContainerLangs = styled.div`
  padding: 0px 16px;
`

const Img = styled.img`
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }
`

const FadeInColumn = styled(Column)`
  ${portfolioFadeInAnimation}
`

/**
 * Auth Layout Header Component
 * @constructor
 */
const DesktopHeader = () => {
  const { account, chainId } = useWeb3React()

  const { notSupportedChain } = useWarningFlag()

  console.log('NotSupportedChain', chainId, notSupportedChain)
  return (
    <>
      <Container>
        <Img
          src={logoIcon}
          width={80}
          onClick={() => {
            window.open('https://blackbunny.app', '_blank')
          }}
        />

        <Box flex={1} ml={18}>
          {account && <Tokens account={account} />}
        </Box>

        <CTAWrapper>
          <LangSelect />
          <ChainSelector />
          <Web3Status />
        </CTAWrapper>
      </Container>
      {notSupportedChain && <WrongNetworkBanner />}
    </>
  )
}

const Tokens = ({ account }: { account: string }) => {
  const { chainId } = useWeb3React()
  const { data, isLoading: loading } = useAccountTokens(chainId, true)

  return (
    <>
      <Box flexDirection="row" alignItems="center">
        <CopyText toCopy={account}>
          <Typography fontWeight={500} color="lightGrayishBlue" mr={9}>
            {shortenAddress(account)}
          </Typography>
        </CopyText>
      </Box>

      {!loading &&
        (data !== undefined && data.value ? (
          <FadeInColumn>
            <Box justifyContent="center" flex={1}>
              <ThemedText.HeadlineLarge fontSize={30} fontWeight={500}>
                {formatNumber(data.value.marketValue, NumberType.PortfolioBalance)}
              </ThemedText.HeadlineLarge>
            </Box>
          </FadeInColumn>
        ) : (
          <Column gap="xs">
            <LoadingBubble height="44px" width="170px" />
            <LoadingBubble height="24px" width="100px" margin="4px 0 0 0" />
          </Column>
        ))}
    </>
  )
}

const LangSelect = () => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const theme = useTheme()

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textSecondary,
  }
  const activeLocale = useActiveLocale()

  return (
    <Box position="relative" ref={ref}>
      <Row
        as="button"
        gap="8"
        className={ChainSelectorCss}
        background={isOpen ? 'accentActiveSoft' : 'none'}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
        {activeLocale ? <Text fontSize={14}>{SHORT_LOCALE_LABEL[activeLocale]}</Text> : null}
      </Row>
      {isOpen && (
        <NavDropdown top="56" left="auto" right="0" ref={modalRef}>
          <ContainerLangs>
            {SUPPORTED_LOCALES.map((locale) => (
              <LanguageMenuItem locale={locale} isActive={activeLocale === locale} key={locale} />
            ))}
          </ContainerLangs>
        </NavDropdown>
      )}
    </Box>
  )
}

const MobileHeader = () => {
  const { drawerOpen, setDrawerOpen } = useMobileHeader()

  return (
    <MobileHeaderContainer>
      <HamburgerButton variant="text" onClick={() => setDrawerOpen(true)}>
        <img src={hamburgerIcon} />
      </HamburgerButton>
      <ProfileButton>
        <Box style={{ marginRight: '12px' }}>
          <LangSelect />
        </Box>
        <ChainSelector />
        <Web3Status />
      </ProfileButton>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <SideMenuContent onClose={() => setDrawerOpen(false)} />
      </Drawer>
    </MobileHeaderContainer>
  )
}

const Header = () => {
  const { windowWidth } = useWindowDimensions()

  return (
    <>
      {windowWidth <= MEDIA_WIDTHS.large && <MobileHeader />}
      <DesktopHeader />
    </>
  )
}

export default Header

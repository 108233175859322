import { Dialog, Typography } from '@mui/material'
import { GradientButton } from 'components/Button/GradientButton'
import Row from 'components/Row'
import { SupportedChainId } from 'constants/chains'
import { useActiveWeb3React } from 'hooks'
import { useTrans } from 'i18n/useTransHook'
import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

import { switchToNetwork } from './switchToNetwork'

const DialogContainer = styled(Dialog)`
  .MuiPaper-root {
    padding: 30px 40px;
    max-width: 600px;
    width: 100%;

    ${MediaQuery.phone`
      margin: 16px;
      padding: 24px;
    `}
  }
`

const Title = styled(Typography)`
  font-size: 28px;

  ${MediaQuery.tablet`
    font-size: 21px;
  `}
`

const Description = styled(Typography)`
  font-size: 20px;
  margin-top: 24px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};

  ${MediaQuery.tablet`
    margin-top: 16px;
    font-size: 16px;
  `}
`

const SwitchBtn = styled(GradientButton)`
  width: fit-content;
  height: auto;
  padding: 12px 32px;
  border-radius: 60px;
  font-size: 17px;
  line-height: 150%;
  margin-top: 48px;

  ${MediaQuery.tablet`
    width: 100%;
    margin-top: 32px;
    font-size: 16px;
  `}
`

export const useWarningFlag = () => {
  const { account, chainId } = useActiveWeb3React()

  if (!chainId) {
    return {
      notSupportedChain: false,
      account,
    }
  }

  return {
    notSupportedChain: +chainId !== SupportedChainId.POLYGON && +chainId !== SupportedChainId.MAINNET,
    account,
  }
}

const useHandleChainSwitch = (toggle?: (targetChain: number) => void) => {
  const { library, connector } = useActiveWeb3React()

  const handleChainSwitch = useCallback(
    (targetChain: number, skipToggle?: boolean) => {
      if (!library?.provider) return
      switchToNetwork({ connector, provider: library.provider, chainId: targetChain })
        .then(() => {
          if (!skipToggle) {
            toggle && toggle(targetChain)
          }
        })
        .catch((error: any) => {
          console.error('Failed to switch networks', error)

          // we want app network <-> chainId param to be in sync, so if user changes the network by changing the URL
          // but the request fails, revert the URL back to current chainId

          if (!skipToggle) {
            toggle && toggle(targetChain)
          }
        })
    },
    [library, toggle, connector],
  )

  return handleChainSwitch
}

export const WrongNetworkBanner = () => {
  const handleChainSwitch = useHandleChainSwitch()

  const toggleChain = useCallback(
    (chain: SupportedChainId) => {
      handleChainSwitch(chain, true)
    },
    [handleChainSwitch],
  )

  const { t } = useTrans('WrongNetwork')

  return (
    <DialogContainer open>
      <Title> {t('Title')}</Title>
      <Description> {t('Description')}</Description>

      <Row gap="8px">
        <SwitchBtn
          onClick={() => {
            toggleChain(SupportedChainId.MAINNET)
          }}
        >
          {t('SwitchTo')} Mainnet
        </SwitchBtn>
        <SwitchBtn
          onClick={() => {
            toggleChain(SupportedChainId.POLYGON)
          }}
        >
          {t('SwitchTo')} Polygon
        </SwitchBtn>
      </Row>
    </DialogContainer>
  )
}

import { Box } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex: 1;
  margin: 40px 0;
`

export const PanelItemBox = styled(Box)`
  align-items: stretch;
`

export const PanelLayout = styled(Box)`
  flex-direction: row;

  ${MediaQuery.extraLarge`
    flex-direction: column;
    ${PanelItemBox} {
      flex: none;
    }
    ${PanelItemBox}:last-child {
      margin-left: 0;
      margin-top: 25px;
    }    
  `}

  &:first-child {
    margin-bottom: 25px;
  }
  &:last-child {
    margin-top: 25px;
  }
`

import daiIcon from 'assets/icons/coins/dai.svg'
import ethIcon from 'assets/icons/coins/ethereum.svg'
import fraxIcon from 'assets/icons/coins/frax.svg'
import magicInternetMoneyIcon from 'assets/icons/coins/magic_internet_money.svg'
import tusdIcon from 'assets/icons/coins/tusd.svg'
import usdcIcon from 'assets/icons/coins/usdc.svg'
import euroIcon from 'assets/icons/fiats/euro.svg'
import gbpIcon from 'assets/icons/fiats/gbp.svg'
import usdIcon from 'assets/icons/fiats/usd.svg'

export const coins = [
  {
    symbol: 'MIM',
    name: 'Magic Internet Money',
    icon: magicInternetMoneyIcon,
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    icon: usdcIcon,
  },
  {
    symbol: 'USDT',
    name: 'Tether USD',
    icon: tusdIcon,
  },
  {
    symbol: 'DAI',
    name: 'Dai',
    icon: daiIcon,
  },
  {
    symbol: 'FRAX',
    name: 'FRAX Coin',
    icon: fraxIcon,
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
    icon: ethIcon,
  },
  {
    symbol: 'MIM',
    name: 'Magic Internet Money',
    icon: magicInternetMoneyIcon,
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    icon: usdcIcon,
  },
  {
    symbol: 'USDT',
    name: 'Tether USD',
    icon: tusdIcon,
  },
  {
    symbol: 'DAI',
    name: 'Dai',
    icon: daiIcon,
  },
  {
    symbol: 'FRAX',
    name: 'FRAX Coin',
    icon: fraxIcon,
  },
]

export const fiats = [
  {
    symbol: 'EUR',
    name: 'Euro',
    icon: euroIcon,
  },
  {
    symbol: 'USD',
    name: 'US Dollar',
    icon: usdIcon,
  },
  {
    symbol: 'GBP',
    name: 'British Pound',
    icon: gbpIcon,
  },
]

import { TabButton } from 'components'
import { useTrans } from 'i18n/useTransHook'
import DelegatedList from 'pages/Dashboard/DelegatedList'

import DelegateView from './DelegateView'
import { PanelLayout } from './DelegateView/styles'
import { LeftLayout } from './DelegateView/styles'
import { RightLayout } from './DelegateView/styles'
import { Container, TabWrapper } from './styles'
import useDelegate, { DelegateTabs } from './useDelegate'

/**
 * Delegate Page
 * @constructor
 */
const DelegatePage = () => {
  const { activeTab, setActiveTab } = useDelegate()
  const { t } = useTrans('Delegate')
  return (
    <Container>
      <TabWrapper>
        <TabButton onClick={() => setActiveTab(DelegateTabs.DELEGATE)} $selected={activeTab === DelegateTabs.DELEGATE}>
          {t('Delegate')}
        </TabButton>
      </TabWrapper>
      <PanelLayout>
        <LeftLayout>
          <DelegateView />
        </LeftLayout>
        <RightLayout>
          <DelegatedList />
        </RightLayout>
      </PanelLayout>
    </Container>
  )
}

export default DelegatePage

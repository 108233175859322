import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

export function fetcherSimple(url: string) {
  return fetch(url).then((response) => response.json())
}

export const fetcherZapperPost = (Authorization: any) => (url: string) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      accept: '*/*',
      Authorization,
    },
  }).then((response) => response.json())
}

export const fetcherZapperGet = (Authorization: any) => (url: string) => {
  return fetch(url, {
    headers: {
      accept: '*/*',
      Authorization,
    },
  }).then((response) => response.json())
}

export const useTransition = <T>(data: T) => {
  const [prev, setPrev] = useState(() => data)

  useEffect(() => {
    if (data) {
      setPrev((state: any) => {
        if (state && data && isEqual(data, state)) {
          return state
        }

        return data
      })
    }
  }, [data])

  return prev
}

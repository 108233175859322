import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { GradientButton } from 'components'
import JSBI from 'jsbi'
import { ApprovalState, useApproval } from 'lib/hooks/useApproval'
import { Dots } from 'pages/Pool/styleds'
import { useCallback, useMemo } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { useBbSwapProxyAddress, useDelegationAddress, useVaultAddress } from 'utils/blackbunny'

import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
import { useToken } from './Tokens'
import { useArgentWalletContract } from './useArgentWalletContract'

const ConfirmButton = styled(GradientButton)`
  align-self: flex-start;
`

function useGetAndTrackApproval(getApproval: ReturnType<typeof useApproval>[1]) {
  const addTransaction = useTransactionAdder()
  return useCallback(() => {
    return getApproval().then((pending) => {
      if (pending) {
        const { response, tokenAddress, spenderAddress: spender } = pending
        addTransaction(response, { type: TransactionType.APPROVAL, tokenAddress, spender })
      }
    })
  }, [addTransaction, getApproval])
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string,
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useApproval(amountToApprove, spender, useHasPendingApproval)
  return [approval, useGetAndTrackApproval(getApproval)]
}

interface IApproveProps {
  spendingToken: string
  children: any
  amount: BigNumber
}

export const ApproveCheckerVault = ({ spendingToken, children, amount, isV3 }: IApproveProps & { isV3: boolean }) => {
  const address = useVaultAddress(isV3)

  const token = useToken(spendingToken)

  const currency = useMemo(() => {
    return token ? CurrencyAmount.fromRawAmount(token, JSBI.BigInt(amount.toString())) : null
  }, [token, amount])

  if (!currency) {
    return null
  }

  return (
    <ApproveCheckerERC20 spender={address} currency={currency}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerDelegationProxy = ({ spendingToken, children, amount }: IApproveProps) => {
  const address = useDelegationAddress()

  const token = useToken(spendingToken)

  const currency = useMemo(() => {
    return token ? CurrencyAmount.fromRawAmount(token, JSBI.BigInt(amount.toString())) : null
  }, [token, amount])

  if (!currency || !address) {
    return null
  }

  return (
    <ApproveCheckerERC20 spender={address} currency={currency}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerSwapProxy = ({
  currency,
  children,
  className,
}: {
  children: any
  currency?: CurrencyAmount<Token>
  className?: string
}) => {
  const address = useBbSwapProxyAddress()

  return (
    <ApproveCheckerERC20 spender={address} currency={currency} className={className}>
      {children}
    </ApproveCheckerERC20>
  )
}

interface ICheckerCommon {
  currency?: CurrencyAmount<Currency>
  children?: any
  spender?: string
  className?: string
}

const ApproveCheckerERC20 = ({ currency, children, spender, className }: ICheckerCommon) => {
  const argentWalletContract = useArgentWalletContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(currency, spender)

  // we need an existence check on parsed amounts for single-asset deposits
  const showApprovalA = !argentWalletContract && approvalA !== ApprovalState.APPROVED && !!currency

  const needApprove = (approvalA === ApprovalState.NOT_APPROVED || approvalA === ApprovalState.PENDING) && showApprovalA

  return (
    <>
      {needApprove ? (
        <ConfirmButton className={className} onClick={approveACallback} disabled={approvalA === ApprovalState.PENDING}>
          <Text fontSize={20} fontWeight={600}>
            {approvalA === ApprovalState.PENDING ? (
              <Dots>
                <Trans>Approving </Trans>
                &nbsp;{currency?.currency.symbol?.toUpperCase()}
              </Dots>
            ) : (
              <>
                <Trans>Approve </Trans>&nbsp;
                {currency?.currency.symbol?.toUpperCase()}
              </>
            )}
          </Text>
        </ConfirmButton>
      ) : (
        children
      )}
    </>
  )
}

import { ButtonProps } from '@mui/material'

import { GradientButtonContained, GradientButtonOutlined, GradientText } from './styles'

interface IGradientButton extends ButtonProps {
  bgColor?: string
  disabled?: boolean
}

export const GradientButton = ({ bgColor, ...props }: IGradientButton) => {
  if (props.variant === 'outlined') {
    const { children, ...rest } = props
    return (
      <GradientButtonOutlined {...rest} $bgColor={bgColor}>
        <GradientText>{children}</GradientText>
      </GradientButtonOutlined>
    )
  }

  return <GradientButtonContained {...props} />
}

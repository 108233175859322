import { useWindowDimensions } from 'hooks/useWindowDimensions'
import { useCallback, useEffect, useState } from 'react'

export interface ITokens {
  limited?: boolean
}

export interface ITokensFlexValues {
  assetFlex: number
  valueFlex: number
}

/**
 * Portfolio Tokens Logic
 */
const useTokens = ({ limited }: ITokens) => {
  const { isMobileDevice } = useWindowDimensions()

  const calcFlexValues = useCallback(() => {
    if (limited) {
      return {
        assetFlex: 1.8,
        valueFlex: 1,
      }
    }
    return {
      assetFlex: 1.7,
      valueFlex: 1.8,
    }
  }, [limited])

  const [flexValues, setFlexValues] = useState(() => calcFlexValues())

  useEffect(() => {
    setFlexValues(calcFlexValues())
  }, [calcFlexValues, limited])

  return {
    flexValues,
    isMobileDevice,
  }
}

export default useTokens

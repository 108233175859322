import { Container } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { GradientButton } from 'components'
import { ButtonOutlined } from 'components/Button'
import Modal from 'components/Modal'
import { Web3ConnectBtn } from 'components/Web3Status'
import { useTrans } from 'i18n/useTransHook'
import { Checkbox } from 'nft/components/layout/Checkbox'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

const ContainerStyled = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;

  ${MediaQuery.phone`
    min-height: 800px;
  `};
`

const ConnectBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  min-height: 600px;
`

const ResponsiveFrame = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  iframe {
    border: transparent;
    height: 100%;
  }
`

/**
 * Buy Sell Page
 * @constructor
 */
const BuyByFiat = () => {
  const { account } = useWeb3React()

  const btnRef = useRef<HTMLDivElement>(null)

  return (
    <Flex flex={1}>
      <div ref={btnRef} id="iframeButton" />

      {account ? (
        <BanxaContent btnRef={btnRef} />
      ) : (
        <ConnectBtnContainer>
          <Web3ConnectBtn />
        </ConnectBtnContainer>
      )}
    </Flex>
  )
}

const BanxaContent = ({ btnRef }: { btnRef: React.RefObject<HTMLDivElement> }) => {
  const isShowed = useRef<boolean>(false)

  const { account } = useWeb3React()

  const handleOpen = useCallback(() => {
    if (isShowed.current || !account) {
      return
    }

    if (!isShowed.current) {
      isShowed.current = true

      // eslint-disable-next-line no-debugger
      setTimeout(() => {
        const banxa = new window.Banxa('blackbunny')

        banxa.iframe(
          '#iframeButton',
          '#iframeTarget',
          {
            fiatType: 'USD',
            coinType: 'ETH',
            fiatAmount: 200,
            walletAddress: account,
            theme: 'dark',
            backgroundColor: '1a1c30',
            primaryColor: '1a8cff',
            returnUrl: 'https://dapp.blackbunny.app',
          },
          '100%', //Optional width parameter – Pass `false` if not needed.
          false, //Optional height parameter – Pass `false` if not needed.
        )

        btnRef.current?.click()

        // remove all childs except first from iframeTarget div
        const iframeTarget = document.getElementById('iframeTarget')
        if (iframeTarget) {
          while (iframeTarget.children.length > 1) {
            iframeTarget.removeChild(iframeTarget.lastChild as Node)
          }
        }
      })
    }
  }, [account, btnRef])

  useEffect(() => {
    handleOpen()
  }, [handleOpen])

  useEffect(() => {
    return () => {
      isShowed.current = false
    }
  })

  return (
    <ContainerStyled>
      <ResponsiveFrame id="iframeTarget"></ResponsiveFrame>
      <DisclaimerModal />
    </ContainerStyled>
  )
}

const DisclaimerModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [agreed, setAgreed] = useState<boolean>(false)

  const handleCheckbox = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setAgreed(!agreed)
    },
    [agreed, setAgreed],
  )

  useEffect(() => {
    if (agreed === false) {
      setIsOpen(true)
    }
  }, [agreed, setIsOpen])

  const navigate = useNavigate()

  const { t } = useTrans('Banxa')

  return (
    <Modal isOpen={isOpen} onDismiss={Function.prototype()} maxWidth="670px">
      <ContainerModal>
        <Header>{t('Disclaimer')}</Header>

        <Text>
          {t('Description')} <LinkStyled href="mailto:support@banxa.com">support.banxa.com.</LinkStyled>
        </Text>

        <CheckboxWrapper>
          <Checkbox id="checkbox" hovered checked={agreed} onChange={handleCheckbox} />
          <CheckboxText onClick={handleCheckbox}>{t('AgreeText')}</CheckboxText>
          <CheckboxText>
            <LinkStyled href="https://banxa.com/terms-of-use" target="_blank">
              Terms of Use
            </LinkStyled>
            ,{' '}
            <LinkStyled href="https://banxa.com/Privacy-and-Cookies-Policy" target="_blank">
              Privacy Policy
            </LinkStyled>
          </CheckboxText>
        </CheckboxWrapper>

        <Footer>
          <ButtonOutlined width="auto" padding="12px 32px" $borderRadius="60px" onClick={() => navigate(-1)}>
            {t('Cancel')}
          </ButtonOutlined>

          <GradientButtonStyled disabled={!agreed} onClick={() => setIsOpen(false)}>
            {t('Continue')}
          </GradientButtonStyled>
        </Footer>
      </ContainerModal>
    </Modal>
  )
}

const LinkStyled = styled.a`
  color: #1a8cff;
  text-decoration: underline;
`

const GradientButtonStyled = styled(GradientButton)`
  width: auto;
  padding: 24px 32px;
  border-radius: 60px;
`

const CheckboxText = styled.label`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;

  color: ${({ theme }) => theme.white};
`

const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
`

const Text = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  color: ${({ theme }) => theme.white};
`

const ContainerModal = styled.div`
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${({ theme }) => theme.palette.veryDarkDesaturatedBlue};
  width: 670px;
`

const Header = styled.div`
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.white};
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
`
export default BuyByFiat

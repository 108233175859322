import { BigNumber } from '@ethersproject/bignumber'
import { ADDRESS_ZERO } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { IReferralRewardAccrued } from 'api'
import { ZERO_ADDRESS, ZERO_HASH } from 'constants/misc'
import { useTokensUsdValue } from 'hooks'
import { useTxTemplate } from 'hooks/tx-template'
import useAssetLogoSource from 'hooks/useAssetLogoSource'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TransactionType } from 'state/transactions/types'
import { useErc20Contract, useReferralsContract } from 'utils/blackbunny'
import { ZERO } from 'utils/isZero'
import { create } from 'zustand'

export const useGenerateRefCode = (code: string) => {
  const contract = useReferralsContract()
  const { account } = useWeb3React()

  const dataFunc = useCallback(async () => {
    if (!account) return
    return await contract?.populateTransaction.registerReferralCode(code, account, ZERO_HASH)
  }, [contract?.populateTransaction, code, account])

  return useTxTemplate(
    TransactionType.GENERATE_REFERRAL_CODE,
    `Generate referral code`,
    dataFunc,
    `generate_ref_code${code}`,
  )
}

export const useWithdrawAccrual = (token: string) => {
  const contract = useReferralsContract()
  const { account } = useWeb3React()

  const dataFunc = useCallback(async () => {
    if (!account) return
    return await contract?.populateTransaction.withdrawReferralRewards(token)
  }, [account, contract?.populateTransaction, token])

  return useTxTemplate(TransactionType.RECEIVE, `Withdraw accruals`, dataFunc, `withdraw_accruals${token}`)
}

export const useGetTokenInfoByAddress = (address: string) => {
  const { chainId } = useWeb3React()
  const isNative = address === ZERO_ADDRESS
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [amountToWithdraw, setAmountToWithdraw] = useState<BigNumber>(ZERO)
  const [decimals, setDecimals] = useState<number>(1)
  const { account } = useWeb3React()

  const memoized = useMemo(() => {
    return [
      {
        address,
        amount: amountToWithdraw.toString(),
      },
    ]
  }, [amountToWithdraw, address])

  const usdVal = useTokensUsdValue(memoized)

  const refContract = useReferralsContract()

  const [srcImg] = useAssetLogoSource(address, chainId, isNative)

  const contract = useErc20Contract(address)

  useEffect(() => {
    if (!account) return

    if (address == ADDRESS_ZERO) {
      setTokenSymbol(chainId === 1 ? 'ETH' : 'MATIC')
      setDecimals(18)

      refContract?.getReferrerRewards(account.toLowerCase(), address).then((_amount) => {
        console.log(account, address, _amount)
        setAmountToWithdraw(_amount)
      })
      return
    }

    if (!contract) return

    refContract?.getReferrerRewards(account.toLowerCase(), address).then((_amount) => {
      setAmountToWithdraw(_amount)
    })

    contract.symbol().then((symbol) => {
      setTokenSymbol(symbol)
    })

    contract?.decimals().then((_decimals) => {
      setDecimals(_decimals)
    })
  }, [account, address, chainId, contract, refContract])

  return {
    srcImg,
    tokenSymbol,
    amountToWithdraw: +amountToWithdraw.toString() / 10 ** decimals,
    usdVal: usdVal.val,
    decimals,
  }
}

export const useGetRefCodeAccruals = (rewards: IReferralRewardAccrued[]) => {
  const tokens = useMemo(() => {
    const tokenMap: { [address: string]: number } = {}

    rewards.forEach((reward) => {
      if (!tokenMap[reward.token.address]) {
        tokenMap[reward.token.address] = 0
      }

      tokenMap[reward.token.address] += +reward.reward
    })

    const _tokens = Object.keys(tokenMap).map((address) => ({
      address,
      amount: tokenMap[address],
    }))

    return _tokens
  }, [rewards])

  const totalAccruals = useTokensUsdValue(tokens)

  return totalAccruals
}

type State = {
  tokens: { [address: string]: number }
  totalAccruals: number | string
  setToken: (address: string, amount: number) => void
}

/**
 * Store for tokens accruals in USD
 */

export const useTokensAccrualsStore = create<State>((set) => ({
  tokens: {},
  totalAccruals: '...',
  setToken: (address, amount) =>
    set((state) => {
      const updatedTokens = {
        ...state.tokens,
        [address]: amount,
      }

      if (isNaN(+amount)) {
        return {
          tokens: updatedTokens,
          totalAccruals: '...',
        }
      }

      const totalAccrualsVal = Object.values(updatedTokens).reduce((acc, item) => {
        return acc + Number(item) // Convert item to a number
      }, 0)

      const totalAccruals = +totalAccrualsVal < 0.01 && totalAccrualsVal !== 0 ? '< $0.01' : '$' + totalAccrualsVal

      return {
        tokens: updatedTokens,
        totalAccruals,
      }
    }),
}))

import { Stack, Typography } from '@mui/material'
import { useIsMobile, useIsTablet } from 'nft/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGenerateRefCode } from './hooks'
import { CancelCreateButton, CodeInput, CreateReferralCodeCard, GenerateBtn, Subtitle } from './styles'

const CharactersAndNumbersRegexp = /^[a-zA-Z0-9\s-]*$/

export const ReferralsCodeGenerator = ({
  variant = 'block',
  onClose,
}: {
  variant?: 'block' | 'modal'
  onClose?: () => void
}) => {
  const [code, setCode] = useState('')

  const [error, setError] = useState<Error | null>(null)

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const isMobileOrTablet = isMobile || isTablet

  const { t } = useTranslation('Referrals')

  const { action, pending } = useGenerateRefCode(code)

  async function handleGenerateCode() {
    try {
      await action()
      onClose?.()
    } catch (error) {
      if (error?.code === 'ACTION_REJECTED') {
        return
      }
      console.log(error)
      setError(error)
    }
  }

  return (
    <CreateReferralCodeCard
      style={{
        marginTop: variant === 'block' ? '' : 0,
        padding: variant === 'block' ? '' : 0,
        backgroundColor: variant === 'block' ? '' : 'transparent',
        borderRadius: variant === 'block' ? '' : 0,
        maxWidth: 670,
      }}
    >
      <Subtitle>{t('GenerateCode')}</Subtitle>
      <Typography mt={24} fontSize={16} color="lightGrayishBlue">
        {t('Code')}
      </Typography>

      <CodeInput
        type="text"
        placeholder=""
        value={code}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!CharactersAndNumbersRegexp.test(event.target.value)) {
            setError(new Error('Only characters and numbers are allowed'))
            return
          }
          setError(null)
          setCode(event.target.value)
        }}
        // InputProps={props.inputProps}
        // value={props.value}
        // placeholder={props.placeholder}
        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //   onChangeValue?.(event.target.value)
        // }}
        // disabled={props.readonly}
      />
      {error && (
        <Typography color="red" mt={12} fontSize={14}>
          {t('Error')}
        </Typography>
      )}
      <Stack
        direction="row"
        mt={40}
        columnGap={16}
        sx={{
          justifyContent: variant === 'block' ? 'flex-start' : 'flex-end',
        }}
      >
        {variant === 'modal' && (
          <CancelCreateButton
            onClick={() => {
              onClose?.()
            }}
          >
            {isMobileOrTablet ? (
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.70711 4.79289C5.31658 4.40237 4.68342 4.40237 4.29289 4.79289C3.90237 5.18342 3.90237 5.81658 4.29289 6.20711L10.5858 12.5L4.29289 18.7929C3.90237 19.1834 3.90237 19.8166 4.29289 20.2071C4.68342 20.5976 5.31658 20.5976 5.70711 20.2071L12 13.9142L18.2929 20.2071C18.6834 20.5976 19.3166 20.5976 19.7071 20.2071C20.0976 19.8166 20.0976 19.1834 19.7071 18.7929L13.4142 12.5L19.7071 6.20711C20.0976 5.81658 20.0976 5.18342 19.7071 4.79289C19.3166 4.40237 18.6834 4.40237 18.2929 4.79289L12 11.0858L5.70711 4.79289Z"
                  fill="#B7BCDD"
                />
              </svg>
            ) : (
              t('Cancel')
            )}
          </CancelCreateButton>
        )}
        <GenerateBtn onClick={handleGenerateCode} disabled={!code || pending}>
          {t('GenerateCode')}
        </GenerateBtn>
      </Stack>
    </CreateReferralCodeCard>
  )
}

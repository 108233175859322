import { ContactBrokerDialog, ContactMeDialog, SelectTokenDialogProvider } from 'blocks'
import BuyByFiat from 'pages/BuyByFiat'
import DashboardPage from 'pages/Dashboard'
import DelegatePage from 'pages/Delegate'
import Header from 'pages/Layout/ProLayout/Header'
import SideMenu from 'pages/Layout/ProLayout/SideMenu'
import PortfolioPage from 'pages/Portfolio'
import ReferralsPage from 'pages/Referrals'
import StakeListPage from 'pages/StakeListPage'
import StakePage from 'pages/StakeListPage/StakePage'
import SwapPage from 'pages/SwapPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  BUY_SELL_URL,
  DASHBOARD_URL,
  DELEGATE_URL,
  PORTFOLIO_URL,
  REFERRALS_URL,
  STAKE_LIST_URL,
  STAKE_URL,
  SWAP_URL,
} from 'routes/paths'

import { Container, Content, RoutesWrapper } from './styles'

const ProRoutes = () => {
  return (
    <Container>
      <SideMenu />
      <SelectTokenDialogProvider>
        <Content>
          <Header />
          <RoutesWrapper>
            <Routes>
              <Route path={DASHBOARD_URL} element={<DashboardPage />} />
              <Route path={STAKE_URL} element={<StakePage />} />
              <Route path={STAKE_LIST_URL} element={<StakeListPage />} />
              <Route path={DELEGATE_URL} element={<DelegatePage />} />
              <Route path={SWAP_URL} element={<SwapPage />} />
              <Route path={PORTFOLIO_URL} element={<PortfolioPage />} />
              <Route path={REFERRALS_URL} element={<ReferralsPage />} />
              <Route path={BUY_SELL_URL} element={<BuyByFiat />} />
              <Route path="*" element={<Navigate to={SWAP_URL} />} />
            </Routes>
          </RoutesWrapper>
        </Content>
      </SelectTokenDialogProvider>

      <ContactBrokerDialog />
      <ContactMeDialog />
    </Container>
  )
}

export default ProRoutes

import { GradientButton, PanelBox } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(PanelBox)`
  padding: 32px;

  ${MediaQuery.screen`
    padding: 20px 10px;
  `};
  ${MediaQuery.tablet`
    padding: 25px 16px;
  `};
`

export const ConfirmButton = styled(GradientButton)`
  align-self: flex-start;

  ${MediaQuery.tablet`
    align-self: stretch;
  `}
`

export const Footer = styled.div`
  margin-top: 30px;
`

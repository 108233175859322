import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { InputAdornment, InputProps, Typography } from '@mui/material'
import { Box } from 'components'
import { StyledBalanceMax } from 'components/CurrencyInputPanel'
import { GreyCard } from 'components/GreyCard/GreyCard'
import { useTrans } from 'i18n/useTransHook'
import { useCallback, useMemo, useState } from 'react'
import { fromWei } from 'utils/fromWei'
import { getBigNumberValue } from 'utils/getBignumberValue'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import { RightTokenSelector } from './RightTokenSelector'
import { AmountValueInput } from './styles'
import { IAmountInput, IAmountWithMax, useAmountInput } from './useAmountInput'

/**
 * Amount Input
 * @constructor
 */
const AmountInput = (props: IAmountInput) => {
  const { onMaxClicked, max, placeholder, balance, decimals, label, rightToken, ...rest } = props
  const { value, onChange } = useAmountInput(props)

  const { showBalanceRow = true, validateBalanceExceedsZero = true } = props

  const maxDisabled = !max || max.isZero()

  const isInvalid = validateBalanceExceedsZero && max && props.inputValue && props.inputValue.gt(max)

  const inputProps = useMemo(() => {
    const result: InputProps = {}

    result.endAdornment = rightToken ? (
      <InputAdornment position="end">
        <RightTokenSelector value={rightToken} />
      </InputAdornment>
    ) : null
    return result
  }, [rightToken])

  const [focused, setFocused] = useState(false)

  const handleOnFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleOnBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const hasBalanceValue = max || balance

  const { t } = useTrans('StakePage')

  return (
    <GreyCard padding={0} isInvalid={isInvalid}>
      {label && (
        <Typography fontSize={15} color="lightGrayishBlue">
          {label}
        </Typography>
      )}
      <Box flexDirection="row" alignItems="center" mt={4} position="relative">
        <AmountValueInput
          error={isInvalid}
          InputProps={inputProps}
          inputProps={{
            // universal input options
            inputMode: 'decimal',
            autoComplete: 'off',
            autoCorrect: 'off',
            // text-specific options
            type: 'number',
            pattern: '^[0-9]*[.,]?[0-9]*$',
            placeholder: placeholder || '0.0',
            minLength: 1,
            maxLength: 79,
            spellCheck: 'false',
            max,
            value,
          }}
          value={value}
          onChange={(event: any) => {
            focused && !rest.disabled ? onChange(event) : undefined
          }}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          {...rest}
        />
      </Box>
      {showBalanceRow && hasBalanceValue ? (
        <Typography fontSize={12} color="lightGrayishBlue">
          {t('Balance')} {formatDecimal(max || balance || ZERO, 2, decimals)}{' '}
          <StyledBalanceMax onClick={onMaxClicked} disabled={maxDisabled}>
            <Trans>Max</Trans>
          </StyledBalanceMax>
          {max && props.inputValue && max.lt(props.inputValue) ? (
            <Typography fontSize={12} color="red">
              <Trans>Insufficient {rightToken?.symbol} balance</Trans>
            </Typography>
          ) : null}
        </Typography>
      ) : null}
    </GreyCard>
  )
}

export const AmountInputWithMax = ({ inputValue, setInputValue, decimals = 18, ...rest }: IAmountWithMax) => {
  const inputAsNumber = useMemo(() => +fromWei(inputValue || ZERO, decimals) || undefined, [inputValue, decimals])

  const onInputHandler = useCallback(
    (val: any) => {
      setInputValue && setInputValue(getBigNumberValue(val ? +val : 0, BigNumber.from(10).pow(decimals)))
    },
    [decimals, setInputValue],
  )

  const maxValue = rest.max || rest.balance

  const onMaxHandler = useCallback(() => {
    setInputValue && setInputValue(maxValue)
  }, [maxValue, setInputValue])

  return (
    <AmountInput
      value={inputAsNumber}
      onUserInput={onInputHandler}
      max={maxValue}
      onMaxClicked={onMaxHandler}
      decimals={decimals}
      inputValue={inputValue}
      {...rest}
    />
  )
}
//
// type WithoutMaxAndBalance = Omit<IAmountWithMax, 'decimals' | 'max'>
//
// const AmountInputNativeToken = (props: WithoutMaxAndBalance) => {
// const { account: address } = useWeb3React()
// const balanceString = useNativeCurrencyBalances(address ? [address] : [])?.[address ?? '']?.toSignificant(3) || '0'
//
// return <AmountInputWithMax {...props} max={parseEther(balanceString)} decimals={18} />
// }
//
// const AmountInputPlainToken = (props: WithoutMaxAndBalance) => {
// const balance = useTokenBalance(props.rightToken?.address)
// const decimals = useTokenDecimals(props.rightToken?.address)
//
// return <AmountInputWithMax {...props} max={balance.result?.[0]} decimals={decimals} />
// }

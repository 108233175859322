import { Typography } from '@mui/material'
import walletIcon from 'assets/icons/empty-wallet.svg'
import { Box } from 'components'
import { InfiniteScrollList } from 'components/InfiniteScroll/InfiniteScrollList'
import ValueLoading from 'components/ValueLoading'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import { useTrans } from 'i18n/useTransHook'
import { ITransactionType } from 'pages/StakeListPage/TransactionList'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { STAKE_URL } from 'routes/paths'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'utils/mediaQuery'
import { formatDecimal } from 'utils/numberWithCommas'

import { IVault, useTokenBalance, useYearnPrices, useYearnVaults } from './hooks'
import {
  Container,
  MobileAssetWrapper,
  MobileDivider,
  MobileInfoWrapper,
  MobileTokenItemWrapper,
  PriceLabel,
  PriceValueLabel,
  SeeMoreButton,
  StakeButton,
  Title,
  TokenItemWrapper,
  WalletButton,
} from './styles'
import { IStakeList } from './useStakeList'

const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  overflow: visible !important;
`

interface IProps {
  vault: IVault
  lastItem: boolean
  limited: boolean
  isCompact: boolean
  prices: {
    [key: string]: string
  }
}

const formatApy = (apy: number) => (+apy * 100).toFixed(2)

const StakeTokenItem = ({
  vault: {
    name,
    apr: {
      forwardAPR: { netAPR },
    },

    token: { address, decimals, symbol },
    address: vaultAddress,
    tvl: { tvl },
    token: { name: display_name },
  },
  lastItem,
  limited,
  isCompact,
}: IProps) => {
  const { t } = useTrans('StakePage')
  const navigate = useNavigate()
  const handleStake = useCallback(
    (type: ITransactionType) => {
      navigate(`${STAKE_URL}?vault=${vaultAddress}`, {
        state: {
          type,
        },
      })
    },
    [navigate, vaultAddress],
  )

  const { balance, loading } = useTokenBalance(address)
  const { balance: vaultBalance, loading: loadingVaultBalance } = useTokenBalance(vaultAddress)

  if (isCompact) {
    return (
      <MobileTokenItemWrapper $noDivider={lastItem}>
        <Box flexDirection="row" alignItems="center">
          <MobileAssetWrapper>
            <Typography fontSize={15}>{display_name === 'ETH' ? 'WETH' : display_name}</Typography>
            <Typography fontSize={14} color={balance.isZero() ? 'lightGrayishBlue' : undefined}>
              <ValueLoading loading={loading}>
                {t('Balance')} {formatDecimal(balance, 2, decimals)} {symbol}
              </ValueLoading>
            </Typography>
          </MobileAssetWrapper>
          <WalletButton onClick={() => handleStake(ITransactionType.STAKE)}>
            <img src={walletIcon} />
          </WalletButton>
        </Box>

        <MobileInfoWrapper>
          <Box>
            <PriceLabel>{t('DEPOSITED')}</PriceLabel>

            <ValueLoading loading={loadingVaultBalance}>
              <PriceValueLabel color={vaultBalance.isZero() ? 'lightGrayishBlue' : undefined}>
                {formatDecimal(vaultBalance, 2, decimals)}
              </PriceValueLabel>
            </ValueLoading>
          </Box>

          <MobileDivider />

          <Box>
            <PriceLabel>{t('TVL')}</PriceLabel>$ {formatDecimal(tvl, 2, 10)}
          </Box>

          <MobileDivider />

          <Box>
            <PriceLabel>APY</PriceLabel>
            <PriceValueLabel>{formatApy(netAPR)}%</PriceValueLabel>
          </Box>
        </MobileInfoWrapper>
      </MobileTokenItemWrapper>
    )
  }

  return (
    <TokenItemWrapper $noDivider={lastItem}>
      <Box flexDirection="column" alignItems="left" flex={1.7}>
        <Typography fontSize={15} ml={12}>
          {display_name === 'ETH' ? 'WETH' : display_name}
        </Typography>
        <Typography fontSize={12} ml={12} color="grey">
          {name}
        </Typography>
      </Box>

      {!limited && (
        <Typography flex={1} fontSize={15} color={balance.isZero() ? 'lightGrayishBlue' : undefined}>
          <ValueLoading loading={loading}>
            {formatDecimal(balance, 2, decimals)}&nbsp; {symbol}
          </ValueLoading>
        </Typography>
      )}

      <Typography flex={1} fontSize={15} color={vaultBalance.isZero() ? 'lightGrayishBlue' : undefined}>
        <ValueLoading loading={loadingVaultBalance}>{formatDecimal(vaultBalance, 2, decimals)}</ValueLoading>
      </Typography>

      <Box flex={1}>
        <Typography fontSize={15}>$ {formatDecimal(tvl, 2, 10)}</Typography>
      </Box>

      <Typography flex={0.8} fontSize={15}>
        {formatApy(netAPR)}%
      </Typography>

      {!limited && (
        <Box flexDirection="row" alignItems="center" flex={1}>
          <StakeButton onClick={() => handleStake(ITransactionType.STAKE)}>{t('Stake')}</StakeButton>
          <StakeButton onClick={() => handleStake(ITransactionType.UNSTAKE)} isUnstake>
            {t('Unstake')}
          </StakeButton>
        </Box>
      )}
    </TokenItemWrapper>
  )
}

const sortByTvl = (a: IVault, b: IVault) => b.tvl.tvl - a.tvl.tvl

/**
 * Stake List
 * @constructor
 */
const StakeList = (props: IStakeList) => {
  const { t } = useTrans('StakePage')

  const { vaultsObject } = useYearnVaults()
  const vaults = useMemo(() => Object.values(vaultsObject).sort(sortByTvl), [vaultsObject])

  const { data: prices } = useYearnPrices()

  const { windowWidth } = useWindowDimensions()
  const isCompact = windowWidth < MEDIA_WIDTHS.screen

  const [more, showMore] = useState(false)

  const list = useMemo(() => {
    if (!vaults) {
      return []
    }

    return !more ? vaults.slice(0, 10) : vaults
  }, [vaults, more])

  return (
    <Container>
      <Box flexDirection="row" alignItems="center">
        <Typography fontSize={24}>{t('Staking')}</Typography>
      </Box>

      {!isCompact && (
        <Title>
          <Typography fontSize={13} color="lightGrayishBlue" flex={1.7}>
            {t('FEATURED VAULTS')}
          </Typography>
          {!props.limited && (
            <Typography fontSize={13} color="lightGrayishBlue" flex={1}>
              {t('BALANCE')}
            </Typography>
          )}
          <Typography fontSize={13} color="lightGrayishBlue" flex={1}>
            {t('DEPOSITED')}
          </Typography>
          <Typography fontSize={13} color="lightGrayishBlue" flex={1}>
            {t('TVL')}
          </Typography>
          <Typography fontSize={13} color="lightGrayishBlue" flex={0.8}>
            {t('APY')}
          </Typography>
          {!props.limited && <Box fontSize={13} color="lightGrayishBlue" flex={1}></Box>}
        </Title>
      )}

      <InfiniteScrollListStyled
        itemsTotal={list}
        perPage={50}
        renderItem={(item: any, index: number) => (
          <StakeTokenItem
            key={item.address}
            lastItem={list.length - 1 === index}
            vault={item}
            limited={!!props.limited}
            isCompact={isCompact}
            prices={prices}
          />
        )}
      />

      {!more && vaults && <SeeMoreButton onClick={() => showMore(!more)}>{t('See more')}</SeeMoreButton>}
    </Container>
  )
}

export default StakeList

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Auth Desktop Header Logic
 */
const useDesktopHeader = () => {
  const { i18n } = useTranslation()

  const handleChangeLanguage = useCallback(
    (type: string) => {
      i18n.changeLanguage(type)
    },
    [i18n],
  )

  return {
    language: i18n.language,
    handleChangeLanguage,
  }
}

export default useDesktopHeader

import gql from 'graphql-tag'
import { useMemo } from 'react'

import { useApiBlockNumber, useApiQuery, useSWR } from './graphql'

const query = gql`
  query referralRewardAccrueds($hash: String) {
    referralRewardAccrueds(where: { referralCodeHash_eq: $hash }) {
      id
      reward
      referralCodeHash
      timestamp
      token {
        address
      }
      executor {
        address
      }
    }
  }
`

export interface IReferralRewardAccrued {
  id: string
  reward: string
  referralCodeHash: string
  timestamp: string
  token: {
    address: string
  }
  executor: {
    address: string
  }
}

export function useReferralRewardAccrueds({ hash }: { hash?: string }): {
  list: IReferralRewardAccrued[]
  loading: boolean
  error: any
} {
  const block = useApiBlockNumber()

  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (hash) {
      vars.hash = hash.toLowerCase()
    }

    return vars
  }, [hash, block])

  const fetchApi = useApiQuery(query, vars)
  const { data, error } = useSWR(`referralRewardAccrueds${JSON.stringify(vars)}`, fetchApi)

  return {
    list: data ? data.referralRewardAccrueds : [],
    loading: !data,
    error,
  }
}

import { Box } from 'components'

import StakeList from './StakeList'
import { Container } from './styles'

/**
 * Stake List Page
 * @constructor
 */
const StakeListPage = () => {
  return (
    <Container>
      <StakeList />
      <Box height={20} />
    </Container>
  )
}

export default StakeListPage

import styled from 'styled-components/macro'

import Box from '../../Box'
import { Button } from '../index'
import type { ITabButton } from './index'

export const TabButtonContainer = styled(Button).attrs<ITabButton>(({ ...attrs }) => ({
  variant: 'text',
  ...attrs,
}))<ITabButton>`
  ${({ $selected }) =>
    $selected &&
    `
  background: linear-gradient(128.01deg, #fe4cff -18.42%, #5badff 80.5%, #cce6ff 187.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  `}

  height: 40px;
  font-size: 18px;
  font-weight: 500;
  padding: 0;
`

export const Divider = styled(Box)`
  background: linear-gradient(142.61deg, #f634f7 10.16%, #1a8cff 64.32%, #cce6ff 122.72%);
  height: 2px;
`

import { Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { SupportedChainId } from 'constants/chains'

export enum ConnectionType {
  INJECTED = 'INJECTED',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  NETWORK = 'NETWORK',
  GNOSIS_SAFE = 'GNOSIS_SAFE',
  MAGIC_LINK = 'MAGIC_LINK',
}

interface ProviderInfo {
  name: string
  icon?: string
  rdns?: string
}

export interface Connection {
  connector: Connector
  getName(): string
  hooks: Web3ReactHooks
  type: ConnectionType
  getIcon?(isDarkMode: boolean): string
  shouldDisplay(): boolean
  isNew?: boolean
  overrideActivate?: (chainId?: SupportedChainId) => boolean
  /** Optionally include isDarkMode when displaying icons that should change with current theme */
  getProviderInfo?: (isDarkMode?: boolean) => ProviderInfo
}

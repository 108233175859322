import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DASHBOARD_URL } from 'routes/paths'

import { APP_TITLE } from './constants'

/**
 * Auth Mobile Header Logic
 */
const useMobileHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const location = useLocation()

  const title = useMemo(() => {
    const titleKey = Object.keys(APP_TITLE).find((key) => location.pathname.startsWith(key))
    return titleKey ? APP_TITLE[titleKey] : APP_TITLE[DASHBOARD_URL]
  }, [location.pathname])

  return {
    title,
    drawerOpen,
    setDrawerOpen,
  }
}

export default useMobileHeader

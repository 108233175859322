import { Dialog } from '@mui/material'
import { Box, Button, GradientButton } from 'components'
import styled from 'styled-components/macro'

export const DialogContainer = styled(Dialog)`
  .MuiPaper-root {
    padding: 30px 40px;
    max-width: 550px;
    width: 100%;
  }
`

export const SendButton = styled(GradientButton)`
  margin-left: 16px;
`

export const MailRow = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

export const CancelButton = styled(Button).attrs({
  variant: 'outlined',
})`
  padding: 0 27px;
  height: 40px;
  border-radius: 20px;
  color: ${({ theme }) => theme.palette.lightGrayishBlue};
  font-size: 15px;

  &,
  &:hover {
    border-color: ${({ theme }) => theme.palette.lightGrayishBlue};
  }
`

export const BuyButton = styled(GradientButton).attrs<{ $selected: boolean }>(({ $selected, theme }) => ({
  variant: $selected ? 'contained' : 'outlined',
  bgColor: theme.palette.veryDarkDesaturatedBlue,
}))<{ $selected: boolean }>`
  height: 40px;
  padding: 0 45px;
  border-radius: 20px;
  ${({ $selected }) =>
    $selected &&
    `
    background: linear-gradient(128.01deg, #FE4CFF -18.42%, #5BADFF 80.5%, #CCE6FF 187.16%);
    padding: 1px 46px;
    `}

  & + & {
    margin-left: 5px;
  }
`

import { initializeConnector } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { Actions, Connector } from '@web3-react/types'
import GNOSIS_ICON from 'assets/images/gnosis.png'
import METAMASK_ICON from 'assets/images/metamask.svg'
import WALLET_CONNECT_ICON from 'assets/images/walletConnectIcon.svg'
import INJECTED_DARK_ICON from 'assets/svg/browser-wallet-dark.svg'
import INJECTED_LIGHT_ICON from 'assets/svg/browser-wallet-light.svg'
import MAGIC_LINK_ICON from 'assets/svg/magic-link.svg'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useSyncExternalStore } from 'react'
import { isMobile } from 'utils/userAgent'

import { RPC_URLS } from '../constants/networks'
import { RPC_PROVIDERS } from '../constants/providers'
import { MagicUniversalConnector } from './magic-wallet'
import { Connection, ConnectionType } from './types'
import { getIsCoinbaseWallet, getIsInjected, getIsMetaMaskWallet } from './utils'
import { WalletConnectV2 } from './WalletConnectV2'

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`)
}

const KEY_MAGIC_NETWORK = 'lastConnectedMagicNetwork'
const lastConnectedMagicNetwork = localStorage.getItem(KEY_MAGIC_NETWORK)

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) =>
    new Network({
      actions,
      urlMap: RPC_PROVIDERS,
      defaultChainId: lastConnectedMagicNetwork ? (lastConnectedMagicNetwork as any) : 1,
    }),
)
export const networkConnection: Connection = {
  getName: () => 'Network',
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
  shouldDisplay: () => false,
}

const getIsCoinbaseWalletBrowser = () => isMobile && getIsCoinbaseWallet()
const getIsMetaMaskBrowser = () => isMobile && getIsMetaMaskWallet()
const getIsInjectedMobileBrowser = () => getIsCoinbaseWalletBrowser() || getIsMetaMaskBrowser()

const getShouldAdvertiseMetaMask = () =>
  !getIsMetaMaskWallet() && !isMobile && (!getIsInjected() || getIsCoinbaseWallet())
const getIsGenericInjector = () => getIsInjected() && !getIsMetaMaskWallet() && !getIsCoinbaseWallet()

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions, onError }))

const injectedConnection: Connection = {
  // TODO(WEB-3131) re-add "Install MetaMask" string when no injector is present
  getName: () => (getIsGenericInjector() ? 'Browser Wallet' : 'MetaMask'),
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  getIcon: (isDarkMode: boolean) =>
    getIsGenericInjector() ? (isDarkMode ? INJECTED_DARK_ICON : INJECTED_LIGHT_ICON) : METAMASK_ICON,
  shouldDisplay: () => true,
  // If on non-injected, non-mobile browser, prompt user to install Metamask
  overrideActivate: () => {
    if (getShouldAdvertiseMetaMask()) {
      window.open('https://metamask.io/', 'inst_metamask')
      return true
    } else if (!getIsInjected()) {
      window.open(`https://metamask.app.link/dapp/${window.location.hostname}`)
      return true
    }
    return false
  },
}
const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>((actions) => new GnosisSafe({ actions }))
export const gnosisSafeConnection: Connection = {
  getName: () => 'Gnosis Safe',
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE,
  getIcon: () => GNOSIS_ICON,
  shouldDisplay: () => false,
}

export const walletConnectV2Connection: Connection = new (class implements Connection {
  private initializer = (actions: Actions, defaultChainId = SupportedChainId.MAINNET) =>
    new WalletConnectV2({ actions, defaultChainId, onError })

  type = ConnectionType.WALLET_CONNECT_V2
  getName = () => 'WalletConnect'
  getIcon = () => WALLET_CONNECT_ICON
  getProviderInfo = () => ({
    name: 'WalletConnect',
    icon: WALLET_CONNECT_ICON,
  })
  shouldDisplay = () => !getIsInjectedMobileBrowser()

  private activeConnector = initializeConnector<WalletConnectV2>(this.initializer)
  // The web3-react Provider requires referentially stable connectors, so we use proxies to allow lazy connections
  // whilst maintaining referential equality.
  private proxyConnector = new Proxy(
    {},
    {
      get: (target, p, receiver) => Reflect.get(this.activeConnector[0], p, receiver),
      getOwnPropertyDescriptor: (target, p) => Reflect.getOwnPropertyDescriptor(this.activeConnector[0], p),
      getPrototypeOf: () => WalletConnectV2.prototype,
      set: (target, p, receiver) => Reflect.set(this.activeConnector[0], p, receiver),
    },
  ) as typeof this.activeConnector[0]
  private proxyHooks = new Proxy(
    {},
    {
      get: (target, p, receiver) => {
        return () => {
          // Because our connectors are referentially stable (through proxying), we need a way to trigger React renders
          // from outside of the React lifecycle when our connector is re-initialized. This is done via 'change' events
          // with `useSyncExternalStore`:
          const hooks = useSyncExternalStore(
            (onChange) => {
              this.onActivate = onChange
              return () => (this.onActivate = undefined)
            },
            () => this.activeConnector[1],
          )
          return Reflect.get(hooks, p, receiver)()
        }
      },
    },
  ) as typeof this.activeConnector[1]

  private onActivate?: () => void

  overrideActivate = (chainId?: SupportedChainId) => {
    // Always re-create the connector, so that the chainId is updated.
    this.activeConnector = initializeConnector((actions) => this.initializer(actions, chainId as number))
    this.onActivate?.()
    return false
  }

  get connector() {
    return this.proxyConnector
  }
  get hooks() {
    return this.proxyHooks
  }
})()

const MAGIC_KEY = 'pk_live_9F248BD63BFC1D0B'

export const setMagicChainId = (chainId: SupportedChainId) => {
  localStorage.setItem(KEY_MAGIC_NETWORK, chainId.toString())
}

export const getConnectionParams = () => {
  const chain = localStorage.getItem(KEY_MAGIC_NETWORK)

  if (chain) {
    const id = parseInt(chain) as SupportedChainId
    return {
      chainId: id,
      rpcUrls: RPC_URLS[id],
    }
  }

  return undefined
}

const [magicConnect, magicLinkHooks] = initializeConnector<MagicUniversalConnector>((actions) => {
  const network = lastConnectedMagicNetwork
    ? (parseInt(lastConnectedMagicNetwork) as SupportedChainId)
    : SupportedChainId.MAINNET

  return new MagicUniversalConnector({
    actions,
    options: {
      apiKey: MAGIC_KEY, // Magic Universal Wallet Publishable API key
      networkOptions: {
        rpcUrl: RPC_URLS[network][0], // RPC URL
        chainId: network, // Chain ID for network
      },
    },
  })
})

export const magicLinkConnection: Connection = {
  getName: () => 'Magic Link',
  connector: magicConnect,
  hooks: magicLinkHooks,
  type: ConnectionType.MAGIC_LINK,
  getIcon: () => MAGIC_LINK_ICON,
  shouldDisplay: () => true,
}

export function getConnections() {
  return [magicLinkConnection, injectedConnection, walletConnectV2Connection, gnosisSafeConnection, networkConnection]
}

export function useGetConnection() {
  return useCallback((c: Connector | ConnectionType) => {
    if (c instanceof Connector) {
      const connection = getConnections().find((connection) => connection.connector === c)
      if (!connection) {
        throw Error('unsupported connector')
      }
      return connection
    } else {
      switch (c) {
        case ConnectionType.INJECTED:
          return injectedConnection
        case ConnectionType.MAGIC_LINK:
          return magicLinkConnection
        case ConnectionType.WALLET_CONNECT_V2:
          return walletConnectV2Connection
        case ConnectionType.NETWORK:
          return networkConnection
        case ConnectionType.GNOSIS_SAFE:
          return gnosisSafeConnection
      }
    }
  }, [])
}

import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { t } from '@lingui/macro'
import { Trade } from '@uniswap/router-sdk'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { SwapRouter } from '@uniswap/universal-router-sdk'
import { FeeOptions, toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { ZERO_HASH } from 'constants/misc'
import { useCallback } from 'react'
import { trace } from 'tracing/trace'
import { useBbSwapProxyAddress, useBbSwapProxyContract, useReferralsContract } from 'utils/blackbunny'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import isZero, { ZERO } from 'utils/isZero'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'
import { ISwapProps, subtractFeeInDataOfRawTx } from 'utils/swapProxyUtils'

import { PermitSignature } from './usePermitAllowance'
import { useSavedRefCode } from './useSavedRefCode'

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`)
  }
}

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`,
    )
  }
}

interface SwapOptions {
  slippageTolerance: Percent
  deadline?: BigNumber
  permit?: PermitSignature
  feeOptions?: FeeOptions
}

export function useUniversalRouterSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  options: SwapOptions,
) {
  const { account, chainId, provider } = useWeb3React()

  const proxyContractAddress = useBbSwapProxyAddress()
  const proxyContract = useBbSwapProxyContract()

  const { refCode, isCodeAvailable } = useSavedRefCode()
  const refContract = useReferralsContract()

  const dataFunc = useCallback(
    async (props: ISwapProps) => {
      let refCodeHash = ZERO_HASH
      if (refCode.code && isCodeAvailable) {
        refCodeHash = (await refContract?.getReferralCodeHash(refCode.code)) as string
      }

      return await proxyContract?.populateTransaction.execute(
        trade?.inputAmount?.currency?.isNative || false,
        trade?.inputAmount?.currency?.wrapped.address || '',
        BigNumber.from(trade?.inputAmount.quotient.toString()),
        props.commands,
        props.inputs,
        options.deadline || ZERO,
        refCodeHash,
      )
    },
    [proxyContract, options, trade, refCode, refContract, isCodeAvailable],
  )

  return useCallback(async (): Promise<TransactionResponse> => {
    return trace('swap.send', async ({ setTraceData, setTraceStatus, setTraceError }) => {
      try {
        if (!account) throw new Error('missing account')
        if (!chainId) throw new Error('missing chainId')
        if (!provider) throw new Error('missing provider')
        if (!trade) throw new Error('missing trade')

        setTraceData('slippageTolerance', options.slippageTolerance.toFixed(2))
        const { calldata: data, value } = SwapRouter.swapERC20CallParameters(trade, {
          slippageTolerance: options.slippageTolerance,
          deadlineOrPreviousBlockhash: options.deadline?.toString(),
          inputTokenPermit: options.permit,
          fee: options.feeOptions,
        })

        const txData = await dataFunc(await subtractFeeInDataOfRawTx(account, data))

        const txn = {
          ...txData,
          from: account,
          to: proxyContractAddress,
          // TODO(https://github.com/Uniswap/universal-router-sdk/issues/113): universal-router-sdk returns a non-hexlified value.
          ...(value && !isZero(value) ? { value: toHex(value) } : {}),
        }

        let gasEstimate: BigNumber
        try {
          gasEstimate = await provider.estimateGas(txn)
        } catch (gasError) {
          setTraceStatus('failed_precondition')
          setTraceError(gasError)
          console.warn(gasError)
          throw new GasEstimationError()
        }
        const gasLimit = calculateGasMargin(gasEstimate)
        setTraceData('gasLimit', gasLimit.toNumber())
        const response = await provider
          .getSigner()
          .sendTransaction({ ...txn, gasLimit })
          .then((response) => {
            if (txn.data !== response.data) {
              throw new ModifiedSwapError()
            }
            return response
          })
        return response
      } catch (swapError: unknown) {
        if (swapError instanceof ModifiedSwapError) throw swapError

        // Cancellations are not failures, and must be accounted for as 'cancelled'.
        if (didUserReject(swapError)) setTraceStatus('cancelled')

        // GasEstimationErrors are already traced when they are thrown.
        if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

        throw new Error(swapErrorToUserReadableMessage(swapError))
      }
    })
  }, [
    dataFunc,
    account,
    proxyContractAddress,
    chainId,
    options.deadline,
    options.feeOptions,
    options.permit,
    options.slippageTolerance,
    provider,
    trade,
  ])
}

import { Grid, Stack } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useTokensByAddress } from 'api'
import { useTranslation } from 'react-i18next'

import { useTokensAccrualsStore } from '../hooks'
import { Card, Subtitle } from '../styles'
import { AccrualsList } from './AccrualsList'

export const Accruals = () => {
  const { t } = useTranslation('Referrals')
  const { account } = useWeb3React()

  const { totalAccruals } = useTokensAccrualsStore()

  const { list: tokens } = useTokensByAddress({
    address: account,
  })

  return (
    <Grid item flex={1}>
      <Card>
        <Stack gap={16} direction="row">
          <Subtitle>{t('Accruals')}</Subtitle>
          <Subtitle color="verySoftBlue">{totalAccruals}</Subtitle>
        </Stack>
        <AccrualsList data={tokens} />
      </Card>
    </Grid>
  )
}

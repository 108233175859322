import { Stack, Typography } from '@mui/material'
import { IToken } from 'api'
import { useScreenSize } from 'hooks/useScreenSize'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { fromWei } from 'utils/fromWei'
import { BN_1E16 } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import { useGetTokenInfoByAddress, useTokensAccrualsStore, useWithdrawAccrual } from '../hooks'
import { Cell, CoinIcon, Row, WithdrawButton } from '../styles'

export const AccrualsItem = ({ data, isLast }: { data: IToken; isLast: boolean }) => {
  const { amountToWithdraw, tokenSymbol, srcImg, usdVal } = useGetTokenInfoByAddress(data.address)

  const { setToken, tokens } = useTokensAccrualsStore()

  useEffect(() => {
    if (tokens && !tokens[data.address] && tokens[data.address] !== +fromWei(usdVal)) {
      setToken(data.address, +fromWei(usdVal))
    }
  }, [data.address, tokens, setToken, usdVal])

  const { t } = useTranslation('Referrals')

  const isMobile = !useScreenSize()['md']

  const { action } = useWithdrawAccrual(data.address)

  return (
    <Row
      columns={2}
      sx={{
        paddingY: isMobile ? 24 : 12,
        alignItems: 'center',
        gap: isMobile ? 0 : 16,
      }}
      style={{
        borderBottom: isLast ? 'unset' : '',
        paddingBottom: isLast ? 0 : '',
      }}
    >
      <Cell>
        <Stack alignItems="center" columnGap={16} direction="row">
          <CoinIcon src={srcImg} />
          <Typography fontSize={16}>{tokenSymbol}</Typography>
        </Stack>
      </Cell>
      <Cell width={isMobile ? '100%' : 'auto'}>
        <Stack
          alignItems={isMobile ? 'stretch' : 'center'}
          justifyContent="space-between"
          columnGap={10}
          direction={isMobile ? 'column' : 'row'}
        >
          {isMobile && (
            <Typography color="lightGrayishBlue" fontSize={13} textTransform="uppercase" mt={20}>
              {t('Collected')}
            </Typography>
          )}

          <Stack direction={isMobile ? 'row' : 'column'}>
            <Typography mr={isMobile ? 12 : 0} fontSize={16} lineHeight={1.5}>
              {formatDecimal(amountToWithdraw, 6, 0)}
            </Typography>

            {usdVal.isZero() ? null : (
              <Typography color="lightGrayishBlue" fontSize={16} lineHeight={1.5}>
                {usdVal.lte(BN_1E16) ? '<0.1$' : '$ ' + formatDecimal(usdVal)}
              </Typography>
            )}
          </Stack>
          <WithdrawButton disabled={amountToWithdraw === 0} onClick={action}>
            {t('Withdraw')}
          </WithdrawButton>
        </Stack>
      </Cell>
    </Row>
  )
}

import { createTheme } from '@mui/material/styles'

import { ColorsBlack } from './colors'

const muiTheme = createTheme({
  spacing: 1,
  palette: {
    mode: 'dark',
    ...ColorsBlack,
  },

  typography: {
    fontFamily: ['Gilroy', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'unset',
          fontWeight: 600,
          color: 'white',
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'white',
        lineHeight: 'normal',
        fontSize: 17,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: ColorsBlack.veryDarkDesaturatedBlue,
          borderRadius: '32px',
          overflow: 'hidden',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorLeft: {
          background: ColorsBlack.veryDarkDesaturatedBlue,
          width: 320,
          '@media (max-width: 480px)': {
            width: '100%',
          },
        },
      },
    },
  },
})

export default muiTheme

import { Box, PanelBox } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(PanelBox)`
  padding: 25px 30px;

  ${MediaQuery.screen`
    padding: 20px 10px;
  `};
  ${MediaQuery.tablet`
    padding: 25px 16px;
  `};
`

export const Title = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 40px 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.veryDarkGrayishBlue};
`

export const Banner = styled(Box)`
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.mostlyDesaturatedDarkBlue};
  border-radius: 7px;
  height: 21px;
  padding: 0 8px;
  margin-left: 9px;
`

export const TokenItemWrapper = styled(Box)<{ $noDivider: boolean }>`
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  height: 65px;
  ${({ $noDivider, theme }) => !$noDivider && `border-bottom: 1px solid ${theme.palette.veryDarkGrayishBlue};`}
`

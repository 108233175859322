import { BigNumber } from '@ethersproject/bignumber'
import { TextFieldProps } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { escapeRegExp } from 'utils'

type TokenSymbol = string

export type IPickerToken = {
  symbol: TokenSymbol
  address?: string
  name?: string
  decimals?: number
  icon?: string
}

export type IAmountInput = TextFieldProps & {
  onMaxClicked?: () => void
  onUserInput?: (input?: string) => void
  prependSymbol?: string
  max?: BigNumber
  rightToken?: IPickerToken
  bgColor?: string
  showBalanceRow?: boolean
  balance?: BigNumber
  decimals: number
  inputValue?: BigNumber
  label?: string
  validateBalanceExceedsZero?: boolean
}

export type IAmountWithMax = IAmountInput & {
  inputValue?: BigNumber
  setInputValue?: (v?: BigNumber) => void
  decimals?: number
  bgColor?: string
  balance?: BigNumber
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
const toNumber = (v: string | number) => (v ? `${+v}` : undefined)

export const useAmountInput = ({ onUserInput, prependSymbol, max, value: propValue }: IAmountInput) => {
  const enforcer = useCallback(
    (nextUserInput: string) => {
      if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
        if (onUserInput) {
          const value = toNumber(nextUserInput) || '0'
          onUserInput(value)
        }
      }
    },
    [onUserInput],
  )

  const onChange = useCallback(
    (event: any) => {
      const value = event.target.value

      if (max) {
        if (+value > +max && max) {
          // @ts-ignore
          const newMax = typeof max === 'string' ? max.toString().replace(/,/g, '.') : max
          enforcer(newMax)
          return
        }
      }

      if (prependSymbol) {
        // cut off prepended symbol
        const formattedValue = value.toString().includes(prependSymbol)
          ? value.toString().slice(1, value.toString().length + 1)
          : value

        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(formattedValue.replace(/,/g, '.'))
      } else {
        enforcer(value.replace(/,/g, '.'))
      }
    },
    [enforcer, max, prependSymbol],
  )

  const value = useMemo(() => {
    const returnValue = prependSymbol && propValue ? prependSymbol + propValue : propValue
    return returnValue || ''
  }, [prependSymbol, propValue])

  return {
    value,
    onChange,
  }
}

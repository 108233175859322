import { ITransactionType } from 'pages/StakeListPage/TransactionList'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Stake Page Logic
 */
const useStakePage = () => {
  const { state }: any = useLocation()
  const [activeTab, setActiveTab] = useState<ITransactionType>(state?.type || ITransactionType.STAKE)

  return {
    activeTab,
    setActiveTab,
  }
}

export default useStakePage

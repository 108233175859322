import { Box } from 'components'
import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

export const Container = styled(Box)`
  flex: 1;
  margin: 40px 0;

  ${MediaQuery.tablet`
    margin: 20px 0;
  `}
`

export const TabWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin-left: 30px;

  ${MediaQuery.phone`
    margin-left: 16px;
  `}
`

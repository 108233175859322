import { toAddress } from '@yearn-finance/web-lib/utils/address'
import { TabButton } from 'components'
import { LoadingBubble } from 'components/Tokens/loading'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useTrans } from 'i18n/useTransHook'
import { ITransactionType } from 'pages/StakeListPage/TransactionList'
import { useMemo } from 'react'

import { useYearnVaults } from '../StakeList/hooks'
import { StakeView, UnStakeView } from './StakeView/StakeView'
import { ButtonWrapper, Container, Content, RightPanel, Title } from './styles'
import useStakePage from './useStakePage'

/**
 * Stake Page
 * @constructor
 */
const StakePage = () => {
  const { activeTab, setActiveTab } = useStakePage()
  const { t } = useTrans('StakePage')

  const { vault: address } = useParsedQueryString()

  const { vaultsObject } = useYearnVaults()

  const targetVault = useMemo(() => {
    return vaultsObject[toAddress((address as string) || '')]
  }, [address, vaultsObject])

  const { token: { name: display_name } = {} } = targetVault || ({} as any)

  return (
    <Container>
      {display_name ? (
        <Title>{display_name === 'ETH' ? 'WETH' : display_name}</Title>
      ) : (
        <LoadingBubble height="38px" width="170px" />
      )}

      <Content>
        <RightPanel style={{ maxWidth: '600px' }}>
          <ButtonWrapper>
            <TabButton
              onClick={() => setActiveTab(ITransactionType.STAKE)}
              $selected={activeTab === ITransactionType.STAKE}
            >
              {t('Stake')}
            </TabButton>
            <TabButton
              onClick={() => setActiveTab(ITransactionType.UNSTAKE)}
              $selected={activeTab === ITransactionType.UNSTAKE}
            >
              {t('Unstake')}
            </TabButton>
          </ButtonWrapper>

          {!targetVault ? (
            <LoadingBubble height="369px" width="100%" />
          ) : (
            <>
              {activeTab === ITransactionType.STAKE ? (
                <StakeView key="stake" type={ITransactionType.STAKE} vault={targetVault} />
              ) : (
                <UnStakeView key="unstake" type={ITransactionType.UNSTAKE} vault={targetVault} />
              )}
            </>
          )}
        </RightPanel>
      </Content>
    </Container>
  )
}

export default StakePage

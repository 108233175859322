import { defaultNS } from 'i18n/config'
import { useTranslation } from 'react-i18next'

export const useTrans = (ns?: string) => {
  const { t, i18n } = useTranslation(ns)

  const transFunc = (key: string) => {
    if (i18n.exists(key, { ns })) {
      return t(key)
    }
    if (i18n.exists(key, { ns: defaultNS })) {
      return t(key, { ns: defaultNS })
    }
    return key
  }

  return { t: transFunc }
}

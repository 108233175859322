import { Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { Box, TypeBox } from 'components'
import { combineActivities, createGroups } from 'components/AccountDrawer/MiniPortfolio/Activity'
import { useLocalActivities } from 'components/AccountDrawer/MiniPortfolio/Activity/parseLocal'
import { useTimeSince } from 'components/AccountDrawer/MiniPortfolio/Activity/parseRemote'
import { Activity } from 'components/AccountDrawer/MiniPortfolio/Activity/types'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import AlertTriangleFilled from 'components/Icons/AlertTriangleFilled'
import { LoaderV2 } from 'components/Icons/LoadingSpinner'
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks'
import useENSName from 'hooks/useENSName'
import { useTrans } from 'i18n/useTransHook'
import { shortenTx } from 'nft/utils/address'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { EllipsisStyle, ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { Banner, Container, FromLabelWrapper, TokenItemContainer, TokenItemWrapper } from './styles'

const ActivityRowDescriptor = styled(ThemedText.BodySmall)`
  color: ${({ theme }) => theme.textSecondary};
  ${EllipsisStyle}
`

const StyledTimestamp = styled(ThemedText.Caption)`
  color: ${({ theme }) => theme.textSecondary};
  font-variant: small;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss02' on;
`

export enum IHistoryType {
  SEND = 'Send',
  RECEIVE = 'Receive',
  BUY = 'Buy',
}

interface IHistoryItem {
  activity: Activity
  lastItem: boolean
}

const HistoryItem = ({ activity, lastItem }: IHistoryItem) => {
  const { chainId, status, title, descriptor, logos, otherAccount, currencies, timestamp, hash, receipt } = activity

  const info = receipt?.info
  const { ENSName } = useENSName(otherAccount)
  const timeSince = useTimeSince(timestamp)

  const explorerUrl = getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)

  return (
    <TokenItemContainer $noDivider={lastItem} onClick={() => window.open(explorerUrl, '_blank')}>
      <TokenItemWrapper>
        <Box flexDirection="row" alignItems="center" flex={1}>
          <TypeBox>
            <PortfolioLogo chainId={chainId} currencies={currencies} images={logos} accountAddress={otherAccount} />
          </TypeBox>
          <Box ml={12}>
            <Typography fontSize={15}>
              <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
            </Typography>
            <Typography fontSize={14} color="lightGrayishBlue">
              {status === TransactionStatus.Pending ? (
                <LoaderV2 />
              ) : status === TransactionStatus.Confirmed ? (
                <StyledTimestamp>{timeSince}</StyledTimestamp>
              ) : (
                <AlertTriangleFilled />
              )}
            </Typography>
          </Box>
        </Box>

        <FromLabelWrapper>
          <ActivityRowDescriptor color="textSecondary">
            {info?.content || descriptor}
            <Banner style={{ width: '120px' }}>{ENSName ?? otherAccount ?? shortenTx(hash, 5, 5)}</Banner>
          </ActivityRowDescriptor>
        </FromLabelWrapper>
      </TokenItemWrapper>
    </TokenItemContainer>
  )
}

/**
 * Portfolio History List
 * @constructor
 */
const History = () => {
  const { account = '' } = useWeb3React()
  const localMap = useLocalActivities(account)
  const { t } = useTrans('Layout')

  const activityGroups = useMemo(() => {
    const allActivities = combineActivities(localMap, undefined, account)
    return createGroups(allActivities)
  }, [localMap, account])

  return (
    <Container>
      <Box flexDirection="row" alignItems="center">
        <Typography fontSize={24}>{t('All Transactions')}</Typography>
      </Box>

      {activityGroups.map(({ title, transactions }) => (
        <Box key={title} mt={35}>
          <Typography fontSize={15} color="veryPaleBlue">
            {title}
          </Typography>
          {transactions.map((item, index) => (
            <HistoryItem key={index} lastItem={transactions.length - 1 === index} activity={item} />
          ))}
        </Box>
      ))}
    </Container>
  )
}

export default History

import { BoxProps, Typography } from '@mui/material'
import { Box, GradientTypography } from 'components'

interface ISwapInfo {
  title: string
  desc: string
}

const SwapInfo = ({ title, desc, ...props }: ISwapInfo & BoxProps) => (
  <Box flexDirection="row" alignItems="center" {...props}>
    <Typography fontSize={15}>{title}</Typography>
    <GradientTypography fontSize={13} ml={10}>
      {desc}
    </GradientTypography>
  </Box>
)

export default SwapInfo

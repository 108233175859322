import Box from '../../Box'
import { IButton } from '../index'
import { Divider, TabButtonContainer } from './styles'

export interface ITabButton extends IButton {
  $selected: boolean
}

const TabButton = ({ children, ...props }: ITabButton) => (
  <TabButtonContainer {...props}>
    <Box width="100%" height="100%">
      <Box flex={1} justifyContent="center" px={25}>
        {children}
      </Box>
      {props.$selected ? <Divider /> : <Box height={2} />}
    </Box>
  </TabButtonContainer>
)

export default TabButton

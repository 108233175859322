import {
  BUY_SELL_URL,
  DASHBOARD_URL,
  DELEGATE_URL,
  MAGIC_WALLET,
  PORTFOLIO_URL,
  STAKE_URL,
  SWAP_URL,
} from 'routes/paths'

export const APP_TITLE: { [key: string]: string } = {
  [DASHBOARD_URL]: 'BlackBunny',
  [BUY_SELL_URL]: 'BlackBunny',
  [STAKE_URL]: 'BlackBunny',
  [DELEGATE_URL]: 'BlackBunny',
  [SWAP_URL]: 'BlackBunny',
  [PORTFOLIO_URL]: 'Portfolio',
  [MAGIC_WALLET]: 'Wallet',
}

import { useWeb3React } from '@web3-react/core'
import { useErc20Contract } from 'utils/blackbunny'

import { useBalance, useDecimals } from './useBalance'

export const useTokenBalance = (token?: string) => {
  const contract = useErc20Contract(token)

  const { account } = useWeb3React()

  return useBalance(contract, account)
}
export const useTokenDecimals = (token?: string) => {
  const contract = useErc20Contract(token)

  return useDecimals(contract)
}

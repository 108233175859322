import Loader from 'components/Icons/LoadingSpinner'
import { ReactQueryProvider } from 'components/react-query/react-query-provider'
import TopLevelModals from 'components/TopLevelModals'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { useReferralCodeFromParams } from 'hooks'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import ProRoutes from 'routes/Pro/ProRoutes'

import ErrorBoundary from '../components/ErrorBoundary'
import Polling from '../components/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded()

  useReferralCodeFromParams()

  return (
    <CookiesProvider>
      <ErrorBoundary>
        <DarkModeQueryParamReader />
        <ApeModeQueryParamReader />
        <Popups />
        <Polling />
        <TopLevelModals />

        <ReactQueryProvider>
          <Suspense fallback={<Loader />}>{isLoaded ? <ProRoutes /> : <Loader />}</Suspense>
        </ReactQueryProvider>
      </ErrorBoundary>
    </CookiesProvider>
  )
}

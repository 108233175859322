import styled from 'styled-components/macro'
import { MediaQuery } from 'utils/mediaQuery'

import Box from '../index'

const PanelBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.veryDarkDesaturatedBlue};
  border-radius: 32px;
  overflow: hidden;

  ${MediaQuery.phone`
    border-radius: 20px;
  `}
`

export default PanelBox

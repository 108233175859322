import { useWeb3React } from '@web3-react/core'
import { magicLinkConnection } from 'connection'
import { useCallback } from 'react'

const initWalletUi = async () => {
  // @ts-ignore
  if (!magicLinkConnection.connector?.magic) {
    return
  }
  // @ts-ignore
  const magic = magicLinkConnection.connector.magic
  const walletInfo = await magic.wallet.getInfo()
  const walletType = walletInfo.walletType

  if (walletType === 'magic') {
    await magic.wallet.showUI()
  }
}

export const useMagicWallet = () => {
  const { chainId } = useWeb3React()

  const open = useCallback(() => {
    if (chainId) {
      initWalletUi()
    }
  }, [chainId])

  return open
}

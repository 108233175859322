import { Stack, Typography } from '@mui/material'
import { useIsMobile } from 'nft/hooks'

export const NoAccruals = () => {
  const isMobile = useIsMobile()

  return (
    <Stack gap={16} marginTop={24} width="100%" justifyContent="center" alignItems="center">
      <svg
        width={isMobile ? 56 : 72}
        height={isMobile ? 56 : 72}
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.12 40.65C52.86 41.88 52.14 43.65 52.32 45.54C52.59 48.78 55.56 51.15 58.8 51.15H64.5V54.72C64.5 60.93 59.43 66 53.22 66H22.92C25.41 63.81 27 60.6 27 57C27 50.37 21.63 45 15 45C12.18 45 9.57 45.99 7.5 47.64V34.53C7.5 28.32 12.57 23.25 18.78 23.25H53.22C59.43 23.25 64.5 28.32 64.5 34.53V38.85H58.44C56.76 38.85 55.23 39.51 54.12 40.65Z"
          stroke="#ACB4FD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 34.5299V23.5199C7.5 19.9499 9.68999 16.7698 13.02 15.5098L36.84 6.50979C40.56 5.12979 44.55 7.85988 44.55 11.8499V23.2498"
          stroke="#ACB4FD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.6764 41.9105V48.0906C67.6764 49.7406 66.3564 51.0905 64.6764 51.1505H58.7964C55.5564 51.1505 52.5864 48.7805 52.3164 45.5405C52.1364 43.6505 52.8564 41.8805 54.1164 40.6505C55.2264 39.5105 56.7564 38.8506 58.4364 38.8506H64.6764C66.3564 38.9106 67.6764 40.2605 67.6764 41.9105Z"
          stroke="#ACB4FD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M21 36H42" stroke="#ACB4FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M27 57C27 60.6 25.41 63.81 22.92 66C20.79 67.86 18.03 69 15 69C8.37 69 3 63.63 3 57C3 53.22 4.74 49.83 7.5 47.64C9.57 45.99 12.18 45 15 45C21.63 45 27 50.37 27 57Z"
          stroke="#ACB4FD"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.75 53.25V57.75L12 60"
          stroke="#ACB4FD"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Typography fontSize={isMobile ? 21 : 28} color="verySoftBlue">
        No rebates yet
      </Typography>
    </Stack>
  )
}

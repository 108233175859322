import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { ZERO_HASH } from 'constants/misc'
import { useSavedRefCode } from 'hooks'
import { useTxTemplate } from 'hooks/tx-template'
import { useCallback } from 'react'
import { TransactionType } from 'state/transactions/types'
import { useReferralsContract, useVaultContract, useVaultMasterchefV2, useVaultMasterchefV3 } from 'utils/blackbunny'
import { ZERO } from 'utils/isZero'

export const useParticipateInVault = (isV3: boolean, vault: string, amount: BigNumber = ZERO) => {
  const contract = useVaultContract(isV3)
  const { refCode, isCodeAvailable } = useSavedRefCode()
  const refContract = useReferralsContract()

  const dataFunc = useCallback(async () => {
    let refCodeHash = ZERO_HASH
    if (refCode.code && isCodeAvailable) {
      refCodeHash = (await refContract?.getReferralCodeHash(refCode.code)) as string
    }
    return await contract?.populateTransaction.deposit(vault, amount, refCodeHash)
  }, [refCode, contract?.populateTransaction, vault, amount, refContract, isCodeAvailable])

  return useTxTemplate(TransactionType.DEPOSIT_VAULT, `Deposit into vault`, dataFunc, `deposit_vault_${vault}`)
}

export const useWithdrawFromVault = (isV3: boolean, vault: string, amount: BigNumber = ZERO) => {
  const contractV2 = useVaultMasterchefV2(vault)
  const contractV3 = useVaultMasterchefV3(vault)

  const { account } = useWeb3React()

  const dataFunc = useCallback(async () => {
    if (isV3) {
      const method = contractV3?.populateTransaction['redeem(uint256,address,address)']
      return method ? await method(amount, account, account) : undefined
    }

    return await contractV2?.populateTransaction.withdraw(amount)
  }, [contractV3, contractV2, isV3, amount, account])

  return useTxTemplate(TransactionType.WITHDRAW_VAULT, `Withdrawn from vault`, dataFunc, `withdraw_vault_${vault}`)
}
